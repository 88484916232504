import React from 'react';
import { Color } from '../../styles';

export const ArrowRight = (
  <svg width="1.9em" height="1.9em" fill="none">
    <path d="M9 0.75L17.4853 9.23528L9 17.7206" stroke="#222A30" strokeWidth="1.875" />
    <path d="M17.4853 9.23528H0.514719" stroke="#222A30" strokeWidth="1.875" />
  </svg>
);

export const ArrowLeft = (
  <svg xmlns="http://www.w3.org/2000/svg" width="3.0em" height="1.6em" fill="#222A30" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" stroke="#222A30" strokeWidth=".25"/>
</svg>
);

export const ArrowDown = (
  <svg width="1.6em" height="1.6em" viewBox="0 0 25 26" fill="none">
    <path d="M24 12L12.6863 23.3137L1.37258 12" stroke={Color.GREEN} strokeWidth="2.5" />
    <path d="M12.6863 23.3137V0.686291" stroke="#D5EE44" strokeWidth="2.5" />
  </svg>
);
