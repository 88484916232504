import React from 'react';
import styled, { css } from 'styled-components';
import { bodyBold, Color, Fonts } from '../../styles';

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const TagTitle = styled.span`
  ${bodyBold};
  color: ${Color.BLACK};
`;

const Tag = styled.span`
  ${Fonts.WORK_SANS};
  font-style: italic;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 165%;
  letter-spacing: -0.02rem;
`;

const Bar = styled.div`
  display: inline-block;
  width: 1px;
  height: 2.2rem;
  background-color: ${Color.PURPLE_GRAY};
`;

const BarTag = ({ title, tag }: { title: string; tag: string }) => (
  <TagWrapper>
    <TagTitle>{title}</TagTitle>
    <Bar></Bar>
    <Tag>{tag}</Tag>
  </TagWrapper>
);

export default BarTag;
