import React, { createContext, useCallback, useContext, useState } from 'react';
import { closeFullscreenOverlay, initFullscreenOverlay, removeTabindex, restoreTabIndex } from '../../utils';
import { BODY_ID, DESKTOP_NAV_ID } from '../../constants';

const initialState = false;

const SetterContext = createContext(undefined);
const StateContext = createContext(undefined);

const OVERLAY_CALLER_ID = 'nav';

const LockScreenContextProvider = ({ children }) => {
  const [locked, setState] = useState(initialState);
  const setLocked = useCallback(isLocked => {
    if (isLocked) {
      initFullscreenOverlay(OVERLAY_CALLER_ID);
      removeTabindex(BODY_ID, OVERLAY_CALLER_ID);
      removeTabindex(DESKTOP_NAV_ID, OVERLAY_CALLER_ID);
    } else {
      closeFullscreenOverlay(OVERLAY_CALLER_ID);
      restoreTabIndex(OVERLAY_CALLER_ID);
    }
    setState(isLocked);
  }, []);
  return (
    <StateContext.Provider value={locked}>
      <SetterContext.Provider value={setLocked}>{children}</SetterContext.Provider>
    </StateContext.Provider>
  );
};

const useSetLockScreenState = () => useContext(SetterContext);
const useLockScreenState = () => useContext(StateContext);

export { LockScreenContextProvider, useSetLockScreenState, useLockScreenState };
