import React from 'react';

export const Close = (
  <svg viewBox="0 0 18 20" fill="none">
    <title>Close</title>
    <line
      x1="0.75"
      y1="-0.75"
      x2="23.3778"
      y2="-0.75"
      transform="matrix(-0.69474 0.719261 -0.69474 -0.719261 16.8826 0.50293)"
      stroke="#2D2D2D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="0.75"
      y1="-0.75"
      x2="23.374"
      y2="-0.75"
      transform="matrix(0.695891 0.718148 -0.695891 0.718148 0.0805664 1.97168)"
      stroke="#2D2D2D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
