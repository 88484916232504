export const NAV_HEIGHT_DESKTOP = 144;
export const NAV_HEIGHT_MOBILE = 80;
export const NAV_HEIGHT_FIXED = 100;

export const Z_INDEX_PAGE_BANNER = 95;
export const Z_INDEX_DESKTOP_NAV = 100;
export const Z_INDEX_MOBILE_CTA_BAR = 110;
export const Z_INDEX_MOBILE_MENU = 115;
export const Z_INDEX_MOBILE_NAV_BAR = 120;
export const Z_INDEX_SNACKBAR = 120;
export const Z_INDEX_MODAL = 119;
export const Z_INDEX_MOBILE_MENU_BUTTON = 130;
export const Z_INDEX_LOCK_SCREEN = 135;
