import React from 'react';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';

import GlobalStyles from '../../../global-styles.js';
import Body from '../Body';
import { DesktopNav } from '../Nav/Desktop';
import { MobileNav } from '../Nav/Mobile';
import SiteFooter from '../Footer';
import { useZendesk } from '../../../hooks';
import { VideoPlayerModal } from '../Modal';
import queryParams from '../../../utils/query-params';

export const UIContainer = ({ children }: any ) => {
  const showNavigation = (queryParams['navType'] != 'noNav');
  /* Only load the Zendesk widget if the site navigation is enabled */
  if (showNavigation) {
    const { loaded } = useZendesk();
  }
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }} />
      <GlobalStyles />
      <DesktopNav enabled={showNavigation}/>
      <MobileNav enabled={showNavigation} />
      <VideoPlayerModal />
      <Body>{children}</Body>
      <SiteFooter />
    </>
  );
};

/* TODO: Component is only partially set up */
interface SvgProps extends React.SVGAttributes<SVGElement> {
  description?: string;
  $width?: number | string;
  $height?: number | string;
  isInformative?: boolean;
  $color?: string;
}

const SvgContainer = styled.svg<SvgProps>`
  color: ${({ $color }) => $color ?? 'currentColor'};
  height: ${({ $height }) => $height ?? '1em'};
  width: ${({ $width }) => $width ?? '1em'};
`;

export const Svg = ({ children, description, width, height, isInformative = false, ...rest }: SvgProps) => {
  if (isInformative && !description) console.error('Informative SVGs require description for accessibility');

  return (
    <SvgContainer
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
      role={isInformative ? 'img' : null}
      aria-hidden={!isInformative}
      aria-labelledby={isInformative ? description : null}
      $width={width}
      $height={height}
      {...rest}>
      {isInformative && <title id={description}>{description}</title>}
      {children}
    </SvgContainer>
  );
};
