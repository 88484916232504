import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Detection, LIGHT_DARK } from '../../../../constants';
import { useSetLockScreenState } from '../../../../contexts/lock-screen-context';
import { Color, Layout, maxWidthContainer, Width, Transition } from '../../../../styles';
import MobileMenu from './MobileMenu';
import { ModalContextActions, useModalDispatch, useModalState } from '../../../../contexts/modal-context';
import { CompanyLogo } from '../../../Svg';
import { usePageState } from '../../SiteContainer';
import { DefaultCtaButton } from '../../../Gui/Button';

const Wrapper = styled.header<{ $navBarShowing: boolean; $atTop: boolean; $theme: 'DARK' | 'LIGHT'; $menuShowing: boolean }>`
  --primary: ${({ $theme }) => ($theme === 'DARK' ? Color.WHITE : Color.BLACK)};
  --secondary: ${({ $theme }) => ($theme === 'DARK' ? Color.BLACK : Color.BLACK)};

  width: 100%;
  position: fixed;
  height: ${Layout.NAV_HEIGHT_MOBILE / 10}rem;
  z-index: ${Layout.Z_INDEX_MOBILE_NAV_BAR};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: transform 300ms ease;
  transform: translateY(${({ $navBarShowing, $atTop }) => ($navBarShowing || $atTop ? 0 : '-100%')});
  background: ${({ $atTop, $menuShowing }) => ($menuShowing ? '#F9F9F9' : $atTop ? 'transparent' : Color.WHITE)};

  > div:first-of-type {
    ${maxWidthContainer()};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .logo-link {
      visibility: ${({ $menuShowing }) => ($menuShowing ? 'hidden' : 'visible')};

      svg {
        width: 14.7rem;

        path {
          fill: ${({ $atTop }) => ($atTop ? 'var(--primary)' : 'var(--secondary)')};
        }
      }
    }
  }

  @media print, screen and (min-width: ${Width.BREAK_MOBILE_NAV}px) {
    display: none;
  }
`;

const NavButton = styled.button<{ $atTop?: boolean; $menuShowing?: boolean; $modalOpen?: boolean; $theme: string }>`
  position: relative;
  width: 15px;
  height: 12px;

  > div {
    position: absolute;
    height: 2px;
    width: 15px;
    background-color: ${({ $atTop, $menuShowing, $modalOpen, $theme }) =>
      $menuShowing || $modalOpen
        ? Color.BLACK
        : $atTop && $theme === LIGHT_DARK.LIGHT
        ? Color.BLACK
        : $atTop && $theme === LIGHT_DARK.DARK
        ? Color.WHITE
        : Color.BLACK};
    border-radius: 100px;

    &:nth-of-type(1) {
      top: 0;
      width: 100%;
      transition: transform ${Transition.DEFAULT_MOBILE};
      transform-origin: 25% 0 0;
      transform: rotate(${({ $menuShowing, $modalOpen }) => ($menuShowing || $modalOpen ? '45deg' : 0)});
    }

    &:nth-of-type(2) {
      top: 5px;
      width: 100%;
      transition: transform ${Transition.DEFAULT_MOBILE};
      transform-origin: 25% 100% 0;
      transform: rotate(${({ $menuShowing, $modalOpen }) => ($menuShowing || $modalOpen ? '-45deg' : 0)});
    }

    &:nth-of-type(3) {
      bottom: 0;
      width: 50%;
      transition: transform ${Transition.DEFAULT_MOBILE};
      transform-origin: 50% 50% 0;
      transform: scale(${({ $menuShowing, $modalOpen }) => ($menuShowing || $modalOpen ? 0 : 1)});
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

const MobileNavBar = ({ enabled }: { enabled: boolean }) => {
  const [menuShowing, setMenuShowing] = useState(false);
  const [navBarShowing, setNavBarShowing] = useState(true);
  const { isOpen: modalOpen, id } = useModalState();
  const dispatch = useModalDispatch();
  const [atTop, setAtTop] = useState(true);
  const lockScreenPosition = useSetLockScreenState();
  const pageTheme = usePageState(state => state.pageTheme);

  useEffect(() => {
    if (menuShowing) {
      lockScreenPosition(true);
    } else {
      lockScreenPosition(false);
    }
  }, [menuShowing, lockScreenPosition]);

  useEffect(() => {
    let scrollPos = 0;
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (window.scrollY < scrollPos) {
            setNavBarShowing(true);
          } else {
            setNavBarShowing(false);
          }

          if (window.scrollY <= 0) {
            setAtTop(true);
          } else {
            setAtTop(false);
          }
          scrollPos = window.scrollY;
          ticking = false;
        });
      }
      ticking = true;
    };

    if (Detection.IS_BROWSER) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onClick = useCallback(() => {
    if (id && modalOpen) {
      dispatch({ type: ModalContextActions.CLOSE, payload: id });
    } else {
      setMenuShowing(!menuShowing);
    }
  }, [id, menuShowing, modalOpen]);
  if (enabled) {
    return useMemo(
      () => (
        <>
          <Wrapper id={'nav'} $navBarShowing={navBarShowing} $atTop={atTop} $theme={pageTheme} $menuShowing={menuShowing}>
            <div>
              {menuShowing ? (
                <DefaultCtaButton className={'nav-cta'} url={'https://home.readylife.com'} theme={'BORDER_BLACK'} onClick={() => setMenuShowing(false)}>
                  Ohio Housing Pilot
                </DefaultCtaButton>
              ) : (
                <Link className={'logo-link'} to={'/'} onClick={() => setMenuShowing(false)}>
                  <CompanyLogo />
                </Link>
              )}
  
              <NavButton
                onClick={onClick}
                $atTop={atTop}
                $theme={pageTheme}
                $menuShowing={menuShowing || modalOpen}
                $modalOpen={modalOpen}>
                <div />
                <div />
                <div />
              </NavButton>
            </div>
          </Wrapper>
          <MobileMenu showing={menuShowing} setMenuShowing={setMenuShowing} />
        </>
      ),
      [atTop, pageTheme, menuShowing, navBarShowing, modalOpen]
    );
  } else {
    return useMemo(
      () => (
        <>
          <Wrapper id={'nav'} $navBarShowing={navBarShowing} $atTop={atTop} $theme={pageTheme} $menuShowing={menuShowing}>
            <div>
              <Link className={'logo-link'} to={'/'} onClick={() => setMenuShowing(false)}>
                <CompanyLogo />
              </Link>
            </div>
          </Wrapper>
        </>
      ),
      [atTop, pageTheme, menuShowing, navBarShowing, modalOpen]
    );
  }
};

export default MobileNavBar;
