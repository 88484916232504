import React from 'react';
import styled from 'styled-components';
import { Link as BaseLink } from 'gatsby';

import { bodyDefault, Color, flex, Fonts, h3Desktop, Layout, maxWidthContainer } from '../../../../styles';
import { NAV_DATA } from '../../../../data/nav';
import { owlSelector } from '../../../../utils';

const Wrapper = styled.nav<{ $showing?: boolean }>`
  position: fixed;
  z-index: ${Layout.Z_INDEX_MOBILE_MENU};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${Color.WHITE};
  visibility: ${({ $showing }) => ($showing ? 'visible' : 'hidden')};
  transition: ${({ $showing }) => ($showing ? 'transform 400ms ease' : 'transform 0ms ease 10ms')};
  transform: translateY(${({ $showing }) => ($showing ? 0 : '-100%')});
  overflow: auto;
`;

const Link = styled(BaseLink)`
  color: ${Color.BLACK};
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 400;
`;

const LinksContainer = styled.div`
  ${maxWidthContainer()};
  padding-top: ${Layout.NAV_HEIGHT_MOBILE + 48}px;
  display: flex;
  flex-direction: column;
  ${owlSelector('top', 32)};

  a {
    ${Fonts.WORK_SANS};
  }
`;

const Details = styled.details`
  &[open] summary {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const NavMenuSummary = styled.summary`
  ${h3Desktop};
  list-style: none;

  &::-webkit-details-marker {
    display: none;
  }

  svg {
    margin-left: 12px;
  }
`;

const MenuLink = styled(BaseLink)`
  color: ${Color.BLACK};
  ${h3Desktop};
`;

const LinkList = styled.div`
  ${flex('flex-start', 'stretch', 'column')};
  margin-top: 16px;
  margin-left: 32px;
  ${owlSelector('top', 18)};

  a {
    ${bodyDefault};
  }
`;

const MobileMenu = ({ showing, setMenuShowing }: { showing?: boolean; setMenuShowing: any }) => {
  return (
    <Wrapper $showing={showing}>
      <LinksContainer>
        {NAV_DATA.map(({ title, menuItems, url }, index) => {
          return menuItems ? (
            <Details key={`Details-${index}`}>
              <NavMenuSummary>
                {title}
                <svg width="13" height="9" viewBox="0 0 13 9" fill="none">
                  <path d="M12 1.5L6.5 6.5L1 1.5" stroke="#222A30" strokeWidth="2" />
                </svg>
              </NavMenuSummary>
              <LinkList>
                {menuItems.map(({ title, tag, url }, index) => (
                  <Link key={`Link-${index}`} to={url} onClick={() => setMenuShowing(false)}>
                    {title}
                    {tag && ` | ${tag}`}
                  </Link>
                ))}
              </LinkList>
            </Details>
          ) : (
            <MenuLink key={`Link-${index}`} to={url} onClick={() => setMenuShowing(false)}>
              {title}
            </MenuLink>
          );
        })}
      </LinksContainer>
    </Wrapper>
  );
};

export default MobileMenu;
