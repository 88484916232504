
import { css } from 'styled-components';

import { Width } from './index';

export const maxWidthContainer = (
  width: number = Width.XL,
  marginSmall: number = Width.MARGIN_SMALL,
  marginMedium: number = Width.MARGIN_MEDIUM
) => css`
  margin: 0 auto;
  width: calc(100% - ${marginSmall * 2}px);

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    width: calc(100% - ${marginMedium * 2}px);
  }

  @media print, screen and (min-width: ${width + marginMedium * 2}px) {
    width: ${width}px;
  }
`;
