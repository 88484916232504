/* global zE */
import { useCallback, useEffect } from 'react';
import useScript from './use-script';

const ZENDESK_KEY = '3713b984-9199-47ee-9272-9835d45e6bff'

const useZendesk = () => {
  const [isLoaded, error] = useScript(`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`, 'ze-snippet');
  // @ts-ignore
  const loaded = isLoaded && Boolean(window.zE);

  const open = useCallback(() => {
    // @ts-ignore
    zE('webWidget', 'show');
    // @ts-ignore
    zE('webWidget', 'open');
  }, []);
  const close = useCallback(() => {
    // @ts-ignore
    zE('webWidget', 'close');
    // @ts-ignore
    zE('webWidget', 'hide');
  }, []);

  useEffect(() => {
    if (loaded) {
      // @ts-ignore
      zE('webWidget', 'hide');
      // @ts-ignore
      zE('webWidget:on', 'close', () => zE('webWidget', 'hide'));

      // @ts-ignore
      window.zESettings = {
        webWidget: {
          contactForm: {
            attachments: false,
          },
        },
      };
    }
  }, [loaded]);

  return { open, close, loaded, error };
};

export default useZendesk;
