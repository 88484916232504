import { css } from 'styled-components';
import { BREAK_MD, BREAK_LG, BREAK_XL } from './Width';

export const margin = (mobile: string | 0, tablet?: string | 0, desktop?: string | 0, largeTablet?: string | 0) => css`
  margin: ${mobile};

  ${typeof tablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_MD}px) {
      margin: ${tablet};
    }`}

  ${typeof largeTablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_LG}px) {
      margin: ${largeTablet};
    }`}

  ${typeof desktop !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      margin: ${desktop};
    }`}
`;

export const marginTop = (mobile: number, tablet?: number, desktop?: number, largeTablet?: number) => css`
  margin-top: ${mobile}px;

  ${typeof tablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_MD}px) {
      margin-top: ${tablet}px;
    }`}

  ${typeof largeTablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_LG}px) {
      margin-top: ${largeTablet}px;
    }`}

  ${typeof desktop !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      margin-top: ${desktop}px;
    }`}
`;

export const marginBottom = (mobile: number, tablet?: number, desktop?: number, largeTablet?: number) => css`
  margin-bottom: ${mobile}px;

  ${typeof tablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_MD}px) {
      margin-bottom: ${tablet}px;
    }`}

  ${typeof largeTablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_LG}px) {
      margin-bottom: ${largeTablet}px;
    }`}

  ${typeof desktop !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      margin-bottom: ${desktop}px;
    }`}
`;

export const marginTopBottom = (
  [mobileTop, mobileBottom]: number[],
  [tabletTop, tabletBottom]: number[],
  [desktopTop, desktopBottom]: number[],
  [largeTabletTop, largeTabletBottom]: number[] = [tabletTop, tabletBottom]
) => css`
  margin-top: ${mobileTop}px;
  margin-bottom: ${mobileBottom}px;

  @media print, screen and (min-width: ${BREAK_MD}px) {
    margin-top: ${tabletTop}px;
    margin-bottom: ${tabletBottom}px;
  }

  ${typeof largeTabletTop !== 'undefined' &&
  typeof largeTabletBottom !== 'undefined' &&
  `@media print, screen and (min-width: ${BREAK_LG}px) {
      margin-top: ${largeTabletTop}px;
      margin-bottom: ${largeTabletBottom}px;
    }`};

  @media print, screen and (min-width: ${BREAK_XL}px) {
    margin-top: ${desktopTop}px;
    margin-bottom: ${desktopBottom}px;
  }
`;

export const marginLeft = (mobile: number, tablet?: number, desktop?: number, largeTablet?: number) => css`
  margin-left: ${mobile}px;

  ${typeof tablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_MD}px) {
      margin-left: ${tablet}px;
    }`}

  ${typeof largeTablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_LG}px) {
      margin-left: ${largeTablet}px;
    }`}

  ${typeof desktop !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      margin-left: ${desktop}px;
    }`}
`;

export const marginRight = (mobile: number, tablet?: number, desktop?: number, largeTablet?: number) => css`
  margin-right: ${mobile}px;

  ${typeof tablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_MD}px) {
      margin-right: ${tablet}px;
    }`}

  ${typeof largeTablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_LG}px) {
      margin-right: ${largeTablet}px;
    }`}

  ${typeof desktop !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      margin-right: ${desktop}px;
    }`}
`;
