import { useEffect, useState } from 'react';
import { Detection } from '../constants';

let loaded = false;
if (Detection.IS_BROWSER)
  window.addEventListener('load', () => {
    loaded = true;
  });

const usePageLoaded = () => {
  const [pageLoaded, setPageLoaded] = useState(loaded);
  useEffect(() => {
    if (Detection.IS_BROWSER) {
      if (loaded) setPageLoaded(true);
      else {
        const clear = setInterval(() => {
          if (loaded) {
            clearInterval(clear);
            setPageLoaded(true);
          }
        }, 10);
      }
    }
  }, []);
  return pageLoaded;
};

export default usePageLoaded;
