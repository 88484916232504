import { css } from 'styled-components';
import { Transition } from '../styles';

export const hover = (prop: string, base: string | number, hover: string | number, noFocus = false, timeMs = Transition.TIME_DEFAULT, ease = Transition.EASE_DEFAULT) => css`
  ${prop}: ${base};
  transition: ${prop} ${timeMs} ${ease};

  @media (any-hover: none) {
    &:hover {
      ${prop}: ${base};
    }

    &:active {
      ${prop}: ${hover};
    }
  }

  @media (any-hover: hover) {
    &:hover {
      ${prop}: ${hover};
    }
  }
`;

export const hoverChild = (
  selector: string,
  prop: string,
  base: string | number,
  hover: string | number,
  noFocus = false,
  timeMs = Transition.TIME_DEFAULT,
  ease = Transition.EASE_DEFAULT
) => css`
  ${selector} {
    ${prop}: ${base};
    transition: ${prop} ${timeMs} ${ease};
  }

  @media (any-hover: none) {
    &:hover {
      ${selector} {
        ${prop}: ${base};
      }
    }

    &:active {
      ${selector} {
        ${prop}: ${hover};
      }
    }
  }

  @media (any-hover: hover) {
    ${!noFocus && `&:focus,`}
    &:hover {
      ${selector} {
        ${prop}: ${hover};
      }
    }
  }
`;

export const hoverMultiple = (
  props: { prop: string, base: string | number, hover: string | number }[],
  noFocus: boolean = false,
  timeMs: string = Transition.TIME_DEFAULT,
  ease: string = Transition.EASE_DEFAULT
) => css`
  transition: ${props.reduce((css, { prop }, index) => {
  if (['z-index'].includes(prop)) return css;
  return css + `${prop} ${timeMs} ${ease}${index !== props.length - 1 ? ',' : ''}`;
}, ``)};

  ${props.reduce(
  (css, { prop, base, hover }) =>
    css +
    `
    ${prop}: ${base};
    @media (any-hover: none) {
      &:hover {
        ${prop}: ${base};
      }

      &:active {
        ${prop}: ${hover};
      }
    }

    @media (any-hover: hover) {
      ${!noFocus ? `&:focus,` : ''}
      &:hover {
        ${prop}: ${hover};
      }
    }
  `,
  ``
)}
`;
