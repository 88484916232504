import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { Color, Fonts, maxWidthContainer, Width, fullColorBackground, hoverChild, flex, flexGap, marginTop } from '../../../styles';
import { LineBreak } from '../../Content';
import { owlSelector } from '../../../utils';
import { FacebookIcon, InstagramIcon, TwitterIcon, LinkedInIcon} from '../../Svg';
import bbb_badge from '../Footer/bbb_badge.webp';
// @ts-ignore
import PrivacyPolicyPDF from '../../assets/Privacy Notice v2 08-23-2022.pdf';
// @ts-ignore
import ECommPolicyPDF from '../../assets/Ready E Comms Policy.docx.pdf';
// @ts-ignore
import TermsAndServicesPDF from '../../assets/Ready Life TOS (V1)_Final.docx.pdf';
// @ts-ignore
import DirectDepositPDF from '../../assets/Ready Pay Direct Deposit Form.pdf';

const Footer = styled.footer`
  ${maxWidthContainer()};
  ${fullColorBackground(Color.WHITE)};
  padding-block-start: 4.8rem;
  padding-block-end: 6.4rem;
  ${owlSelector('block-start', 32)};

  @media screen and (min-width: ${Width.BREAK_LG}px) {
    padding-block-end: 7.2rem;
  }
`;

const LinksSection = styled.div`
  ${flex('flex-start', 'flex-start', 'column')};
  ${flexGap(32, 32, 'unset')};

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    ${flex('space-between', 'center', 'row-reverse')};
  }
`;

const Text = styled.p`
  ${Fonts.WORK_SANS};
  font-weight: 400;
  font-size: 1.4rem;
  line-height: calc(17 / 14);
  letter-spacing: -0.0125em;
  color: ${Color.BLACK};
`;

const Anchor = styled.a`
  ${hoverChild('svg path', 'fill', Color.BLACK, Color.GRAY)};
`;

const SocialLinks = styled.ul`
  list-style: none;
  display: flex;

  & * + li {
    margin-inline-start: 2.4rem;
  }
`;

const LinkWrapper = styled.div`
  ${flex('flex-start', 'flex-start', 'column')};
  > * {
    flex-basis: 100%;
  }

  @media screen and (min-width: ${Width.BREAK_MD}px) {
    > * {
      flex-basis: auto;
    }
  }
  @media screen and (min-width: ${Width.BREAK_XL}px) {
    flex-direction: row;
  }
`;

const ContactLinks = styled.ul`
  list-style: none;
  ${flex('flex-start', 'flex-start', 'column')};
  ${marginTop(16, 16, 0)};
  ${owlSelector('top', 10, 0, 0)};

  @media screen and (min-width: ${Width.BREAK_MD}px) {
    flex-direction: row;
    & * + li {
      &:before {
        content: '|';
        margin-inline-end: 1.6rem;
        margin-inline-start: 1.6rem;
      }
    }
  }

  @media screen and (min-width: ${Width.BREAK_XL}px) {
    /* Added when links are next to the copyright text */
    li:first-of-type {
      &:before {
        content: '|';
        margin-inline-end: 1.6rem;
        margin-inline-start: 1.6rem;
      }
    }
  }
`;

const BBBText = styled.p`
  margin-left: 10px;
  font-size: 1.5rem;
`;

const SiteFooter = () => {
  const year = new Date().getFullYear();
  const { pathname } = useLocation();
  return (
    <Footer>
      <LinksSection>
        <SocialLinks>
          <li>
            <Anchor href="https://www.facebook.com/myreadylife" aria-label="Facebook. Opens in a new tab" target="_blank">
              <FacebookIcon aria-hidden="true" />
            </Anchor>
          </li>
          <li>
            <Anchor href="https://www.linkedin.com/company/myreadylife" aria-label="LinkedIn. Opens in a new tab" target="_blank">
              <LinkedInIcon aria-hidden="true" />
            </Anchor>
          </li>
          <li>
            <Anchor href="https://twitter.com/readylifetweets" aria-label="Twitter. Opens in a new tab" target="_blank">
              <TwitterIcon aria-hidden="true" />
            </Anchor>
          </li>
          <li>
            <Anchor href="https://www.instagram.com/readylifeus/" aria-label="Instagram. Opens in a new tab" target="_blank">
              <InstagramIcon aria-hidden="true" />
            </Anchor>
          </li>
        </SocialLinks>
        <LinkWrapper>
          <Text>©Ready Finance Inc, {year}</Text>
          <ContactLinks>
            <li>
              <Text as={Link} to="/contact">
                Contact Us
              </Text>
            </li>
            <li>
              <Text as="a" href={'/privacy-policy'} target={'_blank'}>
                Privacy Policy
              </Text>
            </li>
            <li>
              <Text as="a" href={'/terms-of-service'} target={'_blank'}>
                Terms of Service
              </Text>
            </li>
            <li>
              <Text as="a" href={'/communications'} target={'_blank'}>
                E-Communications
              </Text>
            </li>
            <li>
              <Text as="a" href={DirectDepositPDF} target={'_blank'}>
                Direct Deposit Form
              </Text>
            </li>
          </ContactLinks>
        </LinkWrapper>
      </LinksSection>
      <Text>
        Disclaimers - All money transmission services for Ready Life are provided by Figure Payments Corporation except in NY, HI and CA, where they are provided by Primis Bank
        (Member FDIC) money transmission services for Ready Life are provided by Figure Payments Corporation.{' '}
        <LineBreak mobile tablet desktop />
        The Ready Pay Visa® Card is issued by Primis Bank (Member FDIC) pursuant to a license from Visa USA Inc.
      </Text>
      {pathname === '/' && (
        <Text>
          *The Ready Pay Account is issued by Figure Payments Corporation except in NY, HI and CA, where it is provided by Primis Bank (Member FDIC). Your Ready Pay Account funds are held at Primis Bank (Member FDIC). The Ready Pay Visa® Debit Card is issued by Primis Bank (Member FDIC) pursuant to a license from Visa U.S.A. Inc., with program management services provided by Figure Payments Corporation.
        </Text>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <Anchor href="https://www.bbb.org/us/ga/atlanta/profile/financial-technology/ready-life-0443-91824466" aria-label="BBB Accredidation. Opens in a new tab" target="_blank">
        <img src={bbb_badge} alt="BBB Accredidation" style={{ width: '200px', height: 'auto' }} />
      </Anchor>
      <Anchor href="https://www.bbb.org/us/ga/atlanta/profile/financial-technology/ready-life-0443-91824466" aria-label="BBB Accredidation. Opens in a new tab" target="_blank">
        <BBBText>BBB Rating: A<br />As of 9/19/23 <br />Click For Profile</BBBText>
      </Anchor>
      </div>
    </Footer>
  );
};

export default SiteFooter;
