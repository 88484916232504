import { useEffect, useState } from 'react';
import usePageLoaded from './use-page-loaded';
import useWindowData from './use-window-data';

const useYoutubeApi = () => {
  const [apiReady, setApiReady] = useState(false);
  const [YTRef, setYTRef] = useState({});
  const YT = useWindowData('YT');
  const loaded = usePageLoaded();
  useEffect(() => {
    if (loaded) {
      window.onYouTubeIframeAPIReady = () => setApiReady(true);
      const tag = document.createElement('script');

      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  }, [loaded]);
  useEffect(() => {
    if (apiReady && YT)
      setYTRef({
        YT,
      });
  }, [apiReady, YT]);
  return YTRef;
};

export default useYoutubeApi;
