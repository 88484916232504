// @flow
import { css } from 'styled-components';
import { BREAK_MD, BREAK_XL } from './Width';

type AlignOptions = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch' | 'baseline';

export const grid = (
  justifyContent: AlignOptions = 'space-evenly',
  justifyItems: AlignOptions = 'center',
  alignContent: AlignOptions = 'center',
  alignItems: AlignOptions = 'flex-start',
  flexWrap: string = 'nowrap'
) => css`
  display: grid;
  justify-content: ${justifyContent};
  justify-items: ${justifyItems};
  align-content: ${alignContent};
  align-items: ${alignItems};
  flex-wrap: ${flexWrap};
`;

export const gridColumnPosition = (mobile: any, tablet?: any, desktop?: any) => css`
  grid-column: ${mobile};

  @media print, screen and (min-width: ${BREAK_MD}px) {
    grid-column: ${tablet};
  }

  @media print, screen and (min-width: ${BREAK_XL}px) {
    grid-column: ${desktop};
  }
`;

export const gridColumns = (mobile: any, tablet?: any, desktop?: any) => css`
  grid-template-columns: repeat(${mobile}, 1fr);

  @media print, screen and (min-width: ${BREAK_MD}px) {
    grid-template-columns: repeat(${tablet}, 1fr);
  }

  @media print, screen and (min-width: ${BREAK_XL}px) {
    grid-template-columns: repeat(${desktop}, 1fr);
  }
`;

export const columnSpacing = (mobile: any, tablet?: any, desktop?: any) => css`
  column-gap: ${mobile}px;

  @media print, screen and (min-width: ${BREAK_MD}px) {
    column-gap: ${tablet}px;
  }

  @media print, screen and (min-width: ${BREAK_XL}px) {
    column-gap: ${desktop}px;
  }
`;

export const rowSpacing = (mobile: any, tablet?: any, desktop?: any) => css`
  row-gap: ${mobile}px;

  @media print, screen and (min-width: ${BREAK_MD}px) {
    row-gap: ${tablet}px;
  }

  @media print, screen and (min-width: ${BREAK_XL}px) {
    row-gap: ${desktop}px;
  }
`;

export const columnStartEnd = (
  [mobileStart, mobileEnd]: number[] | string[],
  [tabletStart, tabletEnd]: number[] | string[] = [mobileStart, mobileEnd],
  [desktopStart, desktopEnd]: number[] | string[] = [tabletStart, tabletEnd]
) => css`
  grid-column: ${mobileStart} / ${mobileEnd};

  @media print, screen and (min-width: ${BREAK_MD}px) {
    grid-column: ${tabletStart} / ${tabletEnd};
  }

  @media print, screen and (min-width: ${BREAK_XL}px) {
    grid-column: ${desktopStart} / ${desktopEnd};
  }
`;

export const gridOrder = (mobile: number, tablet: number = mobile, desktop: number = tablet) => css`
  order: ${mobile};

  @media print, screen and (min-width: ${BREAK_MD}px) {
    order: ${tablet};
  }

  @media print, screen and (min-width: ${BREAK_XL}px) {
    order: ${desktop};
  }
`;
