const ENV_DEV: string = 'development';
const ENV_TEST: string = 'test';
export const IS_DEV: boolean = [ENV_DEV, ENV_TEST].includes(process.env.GATSBY_ENV);
export const IS_BROWSER = typeof window !== 'undefined';
export const IS_LOCALHOST = IS_BROWSER && window.location.hostname === 'localhost';
export const HAS_LOCAL_STORAGE =
  IS_BROWSER &&
  (() => {
    const mod = 'arbitrary_value';
    try {
      localStorage.setItem(mod, mod);
      localStorage.removeItem(mod);
      return true;
    } catch (e) {
      return false;
    }
  })();
// @ts-ignore
export const IS_CYPRESS: boolean = IS_BROWSER && !!window.Cypress;
