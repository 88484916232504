// @flow

import { css } from 'styled-components';

import * as Width from './Width';

export const displayMobile = css`
  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    display: none;
  }
`;

export const displayTablet = css`
  display: none;
  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    display: block;
  }
  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    display: none;
  }
`;

export const displayDesktop = css`
  display: none;
  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    display: block;
  }
`;

export const displayTabletInline = css`
  display: none;
  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    display: inline-block;
  }
  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    display: none;
  }
`;

export const displayDesktopInline = css`
  display: none;
  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    display: inline-block;
  }
`;

export const displayNoneByBreakpoint = (
  display: string,
  mobile: boolean = false,
  tablet: boolean = false,
  desktop: boolean,
  largeTablet: boolean
) => css`
  display: ${mobile ? 'none' : display};

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    display: ${tablet ? 'none' : display};
  }

  ${typeof largeTablet !== 'undefined' &&
  `@media print, screen and (min-width: ${Width.BREAK_LG}px) {
    display: ${largeTablet ? 'none' : display};
  }`};

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    display: ${desktop ? 'none' : display};
  }
`;

export const displayNone = (
  display: string,
  mobile: boolean = false,
  tablet: boolean = false,
  desktop?: boolean,
  largeTablet?: boolean
) => css`
  display: ${mobile ? 'none' : display};

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    display: ${tablet ? 'none' : display};
  }

  ${typeof largeTablet !== 'undefined' &&
  `@media print, screen and (min-width: ${Width.BREAK_LG}px) {
    display: ${largeTablet ? 'none' : display};
  }`};

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    display: ${desktop ? 'none' : display};
  }
`;
