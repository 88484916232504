import React from 'react';
import styled from 'styled-components';
import Banner from '../../SharedSections/Banner';
import { HOLIDAY_DATA } from '../../../data/page/global/HolidayData';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const Body = ({ children, ...rest }: any) => {
  const currentDate = new Date().toISOString().split('T')[0];

  const activeHoliday = HOLIDAY_DATA.find((holiday) => {
    const startDate = new Date(holiday.startDate).toISOString().split('T')[0];
    const endDate = new Date(holiday.endDate).toISOString().split('T')[0];
    return currentDate >= startDate && currentDate <= endDate;
  });

  const isWithinDateRange = activeHoliday !== undefined;

  let bannerContent = null;
  if (isWithinDateRange) {
    if (activeHoliday.holiday == 'Thanksgiving') {
      bannerContent = (
        <Banner
          boldText={
            <>
              Ready Life Customer Support will be closed on 11/23/2023 and 11/24/2023 for {activeHoliday.holiday} so our team can spend time with family and friends.  
              Please email us at <a href="mailto:support@readylife.com">support@readylife.com</a> and we will get back to you after the holiday.
            </>
          }
          bodyText=""
        />
      );
    } 
    else if (activeHoliday.holiday == 'Christmas') {
      bannerContent = (
        <Banner
          boldText={
            <>
              Ready Life Customer Support will be closed on 12/22/2023 and 12/25/2023 for {activeHoliday.holiday} so our team can spend time with family and friends.  
              Please email us at <a href="mailto:support@readylife.com">support@readylife.com</a> and we will get back to you after the holiday.
            </>
          }
          bodyText=""
        />
      );
    } 
    else if (activeHoliday.holiday == 'New Years') {
      bannerContent = (
        <Banner
          boldText={
            <>
              Ready Life Customer Support will be closed on 12/29/2023 and 01/01/2024 for {activeHoliday.holiday} so our team can spend time with family and friends.  
              Please email us at <a href="mailto:support@readylife.com">support@readylife.com</a> and we will get back to you after the holiday.
            </>
          }
          bodyText=""
        />
      );
    } 
    else {
      bannerContent = (
        <Banner
          boldText={
            <>
              Ready Life Customer Support will be closed on {activeHoliday.endDate} for {activeHoliday.holiday} so our team can spend time with family and friends.  
              Please email us at <a href="mailto:support@readylife.com">support@readylife.com</a> and we will get back to you after the holiday.
            </>
          }
          bodyText=""
        />
      );
    }
  }

  return (
    <Wrapper id={'body'} {...rest}>
      {bannerContent}
      {children}
    </Wrapper>
  );
};

export default Body;