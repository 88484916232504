import { css } from 'styled-components';
import { Width } from '../styles';

export const owlSelector = (marginDirection: string, mobile: number, tablet?: number, desktop?: number, largeTablet?: number) => css`
  > * + * {
    margin-${marginDirection}: ${mobile}px;

    ${
  typeof tablet !== 'undefined' &&
  `
      @media print, screen and (min-width: ${Width.BREAK_MD}px) {
        margin-${marginDirection}: ${tablet}px;
      }`
}

    ${
  typeof largeTablet !== 'undefined' &&
  `
      @media print, screen and (min-width: ${Width.BREAK_LG}px) {
        margin-${marginDirection}: ${largeTablet}px;
      }`
}

    ${
  typeof desktop !== 'undefined' &&
  `
      @media print, screen and (min-width: ${Width.BREAK_XL}px) {
        margin-${marginDirection}: ${desktop}px;
      }`
}
  }
`;
