// @flow

import React, { useState, useEffect, useCallback, useRef } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { normalizeIndex, removeTabindexByElement, restoreTabindexByElement } from '../../../utils';
import { useHammerSwipe, useIntersectionObserver, usePrevious } from '../../../hooks';
import { flex } from '../../../styles';

function addRealIndex(items: any[]): any[] {
  return items.reduce(
    (acc, item, index) => [
      ...acc,
      {
        ...item,
        realIndex: index,
      },
    ],
    []
  );
}

function addAriaSlideLabels(items: any[]): any[] {
  return items.reduce(
    (acc, item, index) => [
      ...acc,
      {
        ...item,
        ariaSlideLabel: `Slide ${index + 1} of ${items.length}`,
      },
    ],
    []
  );
}

function setMinimumLength(items: any[]): any[] {
  if (items.length < 7) return setMinimumLength([...items, ...items]);
  else return items;
}

function unshift(arr: any[]): any[] {
  const last = arr.pop();
  return [last, ...arr];
}

function shift(arr: any[]): any[] {
  const first = arr.shift();
  return [...arr, first];
}

export type CarouselConsumerProps = {
  currentIndex: number;
  slideContent: any[];
  slidePositionX: number[];
  slideOpacity: number[];
  onScreen: boolean;
  changeSlide: () => void;
  content: any[],
  changeSlideByIndex: (slideButtonIndex: number) => void;
  CAROUSEL_SLIDE_CLASSNAME: string;
};

const Wrapper = styled.div`
  ${flex()};
  width: 100%;
  flex-direction: column;
`;

type Props = {
  children: (props: CarouselConsumerProps) => ReactNode;
  label: string;
  data: any[];
  startOnRandomIndex?: boolean;
  changeInterval?: number;
  wrapperRef?: any;
};

const CAROUSEL_SLIDE_CLASSNAME = 'carousel-slide';

const getRandomStartIndex = numSlides => Math.floor(Math.random() * numSlides);

const Carousel = ({ children, label, data, startOnRandomIndex = false, wrapperRef, changeInterval }: Props) => {
  const startIndex = useRef(startOnRandomIndex ? getRandomStartIndex(data.length) : 0);
  // Real slide index is available as slideContent[index].realIndex (see addRealIndex)
  const [currentIndex, setCurrentIndex] = useState(startIndex.current);
  const [content, setContent] = useState([]);
  const [slideContent, setSlideContent] = useState([]);
  const [intervalCount, setIntervalCount] = useState(0);
  const lastCount = usePrevious(intervalCount);
  const intervalRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (wrapperRef?.current?.childNodes) {
        [...wrapperRef.current.childNodes].forEach((el, index) => {
          if (el.classList.contains(CAROUSEL_SLIDE_CLASSNAME)) {
            if (el.dataset.active !== 'true') {
              removeTabindexByElement(el);
            } else restoreTabindexByElement(el);
          }
        });
      }
    }, 10);
    //eslint-disable-next-line
  }, [currentIndex]);
  /**
   * Use this position array to style the child carousel
   * Simple example: mobile rotating carousel would have all slides stacked on top of each other.
   * Slide at position 1 would be at translateX(0), -1 and 1 would be at translateX(-100%) and (100%)
   * These and other slides can be positioned and transitions / opacity set based on how it looks in testing
   * slides at -3 and 3 are generally being shifted to the front / back of the array so should be at opacity 0 and no transition
   */
  const [slidePositionX, setSlidePositionX] = useState([-3, -2, -1, 0, 1, 2, 3]);
  useEffect(() => {
    const content = setMinimumLength(addRealIndex(addAriaSlideLabels(data)));
    setContent(content);
    setSlideContent([
      content[normalizeIndex(startIndex.current - 3, content.length)],
      content[normalizeIndex(startIndex.current - 2, content.length)],
      content[normalizeIndex(startIndex.current - 1, content.length)],
      content[startIndex.current],
      content[normalizeIndex(startIndex.current + 1, content.length)],
      content[normalizeIndex(startIndex.current + 2, content.length)],
      content[normalizeIndex(startIndex.current + 3, content.length)],
    ]);
  }, [data]);

  const changeSlide = useCallback(
    (left: boolean = false, preserveInterval: boolean = false) => {
      if (!preserveInterval && intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      const index = normalizeIndex(currentIndex + (left ? 1 : -1), content.length);
      const newPositionX = left ? unshift(slidePositionX) : shift(slidePositionX);
      setSlidePositionX(newPositionX);
      if (left) {
        setSlideContent(slideContent =>
          slideContent.reduce((acc: any[], slide: any, contentIndex: number) => {
            if (newPositionX[contentIndex] === 3) {
              return [...acc, content[normalizeIndex(index + 3, content.length)]];
            }
            return [...acc, slide];
          }, [])
        );
      } else {
        setSlideContent(slideContent =>
          slideContent.reduce((acc: any[], slide: any, contentIndex: number) => {
            if (newPositionX[contentIndex] === -3) {
              return [...acc, content[normalizeIndex(index - 3, content.length)]];
            }
            return [...acc, slide];
          }, [])
        );
      }
      setCurrentIndex(index);
    },
    [content, currentIndex, slidePositionX]
  );
  // pass in a data index to reset the carousel to that index (for clickable buttons)
  const changeSlideByIndex = useCallback(
    index => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      const curIndex = content[currentIndex].realIndex;
      if (index === normalizeIndex(curIndex - 1, data.length)) {
        changeSlide();
        return;
      } else if (index === normalizeIndex(curIndex + 1, data.length)) {
        changeSlide(true);
        return;
      }
      setSlidePositionX([-3, -2, -1, 0, 1, 2, 3]);
      setSlideContent([
        content[normalizeIndex(index - 3, content.length)],
        content[normalizeIndex(index - 2, content.length)],
        content[normalizeIndex(index - 1, content.length)],
        content[index],
        content[normalizeIndex(index + 1, content.length)],
        content[normalizeIndex(index + 2, content.length)],
        content[normalizeIndex(index + 3, content.length)],
      ]);

      setCurrentIndex(index);
    },
    [content, currentIndex, data.length, changeSlide]
  );
  const [observerRef, onScreen] = useIntersectionObserver();
  useEffect(() => {
    if (changeInterval && onScreen) {
      intervalRef.current = setInterval(() => setIntervalCount(count => count + 1), changeInterval);
    }
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line
  }, [onScreen, changeSlide]);
  useEffect(() => {
    if (intervalCount !== 0 && intervalCount !== lastCount) changeSlide(true, true);
  }, [intervalCount, lastCount, changeSlide]);
  const hammerRef = useHammerSwipe(changeSlide, () => changeSlide(true));
  const setRefs = useCallback(
    ref => {
      hammerRef.current = ref;
      observerRef.current = ref;
    },
    [hammerRef, observerRef]
  );
  return (
    <Wrapper role="region" ref={setRefs} aria-label={label}>
      {children({
        CAROUSEL_SLIDE_CLASSNAME,
        currentIndex,
        content,
        slideContent,
        slidePositionX,
        onScreen,
        changeSlide,
        changeSlideByIndex,
      })}
    </Wrapper>
  );
};

export default Carousel;
