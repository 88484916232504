import { css } from 'styled-components';

export const BREAK_XS = 340;
export const BREAK_SM = 500;
export const BREAK_MD = 768;
//BREAK_LG is the screen width of iPad Pro's
export const BREAK_LG = 1024;
export const XL = 1200;
export const XXL = 1600;

export const BREAK_MOBILE_NAV = 1050;

export const MARGIN_SMALL = 24;
export const MARGIN_MEDIUM = 40;

export const WIDTH_SM = 375 - MARGIN_SMALL * 2;
export const WIDTH_MD = BREAK_MD - MARGIN_MEDIUM * 2;

export const BREAK_XL = XL + MARGIN_MEDIUM * 2;

export const width = (
  mobile: number | string,
  tablet?: number | string,
  desktop?: number | string,
  largeTablet?: number | string
) => css`
  width: ${typeof mobile === 'string' ? mobile : `${mobile}px`};

  ${tablet &&
  `@media print, screen and (min-width: ${BREAK_MD}px) {
      width: ${typeof tablet === 'string' ? tablet : `${tablet}px`};
    }`};

  ${largeTablet &&
  `@media print, screen and (min-width: ${BREAK_LG}px) {
        width: ${typeof largeTablet === 'string' ? largeTablet : `${largeTablet}px`};
      }`};

  ${desktop &&
  `@media print, screen and (min-width: ${BREAK_XL}px) {
      width: ${typeof desktop === 'string' ? desktop : `${desktop}px`};
    }`};
`;

export const maxWidth = (
  mobile: number | string,
  tablet?: number | string,
  desktop?: number | string,
  largeTablet?: number | string
) => css`
  max-width: ${typeof mobile === 'string' ? mobile : `${mobile}px`};

  ${tablet &&
  `@media print, screen and (min-width: ${BREAK_MD}px) {
      max-width: ${typeof tablet === 'string' ? tablet : `${tablet}px`};
    }`};

  ${largeTablet &&
  `@media print, screen and (min-width: ${BREAK_LG}px) {
        max-width: ${typeof largeTablet === 'string' ? largeTablet : `${largeTablet}px`};
      }`};

  ${desktop &&
  `@media print, screen and (min-width: ${BREAK_XL}px) {
      max-width: ${typeof desktop === 'string' ? desktop : `${desktop}px`};
    }`};
`;

export const marginSpacedWidth = (mobile: number, tablet: number, desktop: number, numColumns: number, largeTablet?: number) => css`
  ${mobile && `width: calc(${100 / numColumns}% - ${mobile * (numColumns - 1)}px);`};

  ${tablet &&
  `@media print, screen and (min-width: ${BREAK_MD}px) {
      width: calc(${100 / numColumns}% - ${tablet * (numColumns - 1)}px);
    }`};

  ${largeTablet &&
  `@media print, screen and (min-width: ${BREAK_LG}px) {
      width: calc(${100 / numColumns}% - ${largeTablet * (numColumns - 1)}px);
    }`};

  ${desktop &&
  `@media print, screen and (min-width: ${BREAK_XL}px) {
      width: calc(${100 / numColumns}% - ${desktop * (numColumns - 1)}px);
    }`};
`;
