import type { LightDark } from '../constants';
import { LIGHT_DARK } from '../constants';

export const addClassName = (className: string, styledComponentsClassName: string = '') =>
  styledComponentsClassName ? `${className} ${styledComponentsClassName}` : className;

export const addFocusClassName = (themeOrIsDark?: string | undefined, className?: string | undefined) =>
  (typeof themeOrIsDark === 'boolean' && themeOrIsDark) || themeOrIsDark === LIGHT_DARK.DARK
    ? addClassName('focus-dark', className)
    : className;
