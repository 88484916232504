import React from 'react';

export const CompanyLogo = () => (
  <svg width="23.7em" height="10.8em" viewBox="0 0 177 48" fill="none">
    <title>Ready Life</title>
    <g clipPath="url(#clip0_332_3266)">
      <path
        d="M67.8917 17.092V19.3342H67.6216C66.8565 19.3187 66.0991 19.4873 65.4127 19.8252C64.7267 20.1632 64.1313 20.6613 63.6775 21.2772C62.7096 22.545 62.2035 24.105 62.2422 25.6996V32.2731H60V17.3292H62.2422V21.007C62.6178 19.8497 63.3554 18.8446 64.3468 18.1392C65.3379 17.4342 66.5297 17.0671 67.7456 17.092H67.8917Z"
        fill="#222A30"
      />
      <path
        d="M83.8254 24.8054C83.8254 22.5923 83.168 20.7663 81.8533 19.3272C80.458 17.8323 78.6456 17.085 76.4154 17.085C74.1852 17.085 72.3713 17.8323 70.974 19.3272C69.6593 20.7636 69.002 22.5895 69.002 24.8054C68.9372 26.8317 69.6779 28.8015 71.0616 30.2832C72.4787 31.7781 74.312 32.5243 76.5615 32.5219C78.3363 32.5219 79.7813 32.1045 80.8964 31.2692C82.0266 30.4386 82.8893 29.2964 83.3796 27.9825H80.9254C80.0491 29.6576 78.6053 30.4952 76.5945 30.4952C75.9247 30.5189 75.2573 30.4092 74.6302 30.1727C74.0034 29.9363 73.4298 29.5782 72.9422 29.1181C72.0039 28.2162 71.4205 27.0069 71.2988 25.7108H83.8215L83.8254 24.8054ZM71.3318 23.6074C71.4833 22.3876 72.0539 21.2578 72.9461 20.412C73.9112 19.5818 75.1422 19.1252 76.4154 19.1252C77.6886 19.1252 78.9196 19.5818 79.8847 20.412C80.7766 21.2578 81.3475 22.3876 81.4991 23.6074H71.3318Z"
        fill="#222A30"
      />
      <path
        d="M115.999 11.3511V19.7505C114.782 17.9781 113.049 17.092 110.799 17.092C108.55 17.092 106.747 17.8393 105.39 19.3343C104.11 20.8722 103.408 22.8106 103.408 24.8121C103.408 26.814 104.11 28.7519 105.39 30.2903C106.051 31.0244 106.864 31.605 107.772 31.9918C108.681 32.3782 109.663 32.5616 110.649 32.529C113.021 32.529 114.804 31.6523 115.999 29.8996V32.2914H118.242V11.3654L115.999 11.3511ZM114.776 28.8074C114.308 29.3605 113.72 29.7988 113.057 30.0891C112.393 30.3798 111.672 30.5147 110.949 30.4837C110.218 30.5081 109.49 30.3705 108.818 30.081C108.146 29.7911 107.547 29.3562 107.063 28.8074C106.153 27.6713 105.657 26.259 105.657 24.8032C105.657 23.3474 106.153 21.935 107.063 20.7986C107.547 20.2505 108.147 19.8164 108.819 19.5273C109.49 19.2381 110.218 19.1009 110.949 19.1261C111.672 19.0947 112.393 19.2288 113.056 19.5188C113.72 19.8087 114.308 20.2467 114.776 20.7986C115.648 21.9517 116.12 23.3575 116.12 24.8032C116.12 26.2485 115.648 27.6547 114.776 28.8074Z"
        fill="#222A30"
      />
      <path
        d="M126.098 38.1966H123.677L126.398 32.0688L120.299 17.333H122.779L127.592 29.589L132.493 17.333H134.944L126.098 38.1966Z"
        fill="#222A30"
      />
      <path
        d="M100.53 24.4876C100.526 24.1418 100.501 23.7965 100.453 23.4542V23.4287C99.9532 19.7768 96.9327 16.9644 93.2809 16.9644C92.5809 16.966 91.8855 17.073 91.2177 17.2823C89.8274 17.5602 88.5643 18.2803 87.6166 19.3345C86.3383 20.8741 85.6387 22.812 85.6387 24.8128C85.6387 26.8135 86.3383 28.7514 87.6166 30.2906C88.2771 31.0247 89.0898 31.6053 89.9984 31.9921C90.9069 32.3789 91.8886 32.5622 92.8754 32.5293C95.0994 32.5293 96.7979 31.7514 97.9881 30.2103L98.2257 29.9344V32.2921H100.526V24.6228C100.522 24.5752 100.53 24.5314 100.53 24.4876ZM96.9986 28.808C96.5308 29.3607 95.9428 29.7991 95.2793 30.0898C94.6161 30.3805 93.8948 30.5154 93.1712 30.4844C92.5022 30.489 91.8379 30.3727 91.2103 30.141C90.8123 29.982 90.434 29.7786 90.0817 29.5348C89.7914 29.32 89.5243 29.0751 89.2856 28.8042C88.3778 27.667 87.8837 26.255 87.8837 24.8C87.8837 23.3449 88.3778 21.933 89.2856 20.7954C89.7701 20.2477 90.3697 19.8136 91.0414 19.5245C91.7131 19.2353 92.4406 19.0981 93.1712 19.1229C93.8948 19.09 94.6161 19.2233 95.2797 19.5136C95.9432 19.8035 96.5316 20.2423 96.9986 20.7954C97.8703 21.9485 98.342 23.3546 98.342 24.8C98.342 26.2453 97.8703 27.6515 96.9986 28.8042V28.808Z"
        fill="#222A30"
      />
      <path
        d="M142.074 32.0989V11.2384H144.309V32.0989H142.074ZM148.096 14.6953V11.7748H150.51V14.6953H148.096ZM148.185 32.0989V17.1987H150.42V32.0989H148.185ZM155.31 32.0989V19.1654H152.747V17.1987H155.31V15.8875C155.31 14.2585 155.797 13.0364 156.77 12.2221C157.763 11.4074 159.114 11 160.823 11H161.002V13.1159H160.704C159.671 13.1159 158.886 13.3542 158.349 13.8313C157.813 14.2879 157.545 15.0333 157.545 16.0662V17.1987H161.002V19.1654H157.545V32.0989H155.31ZM169.759 32.3372C168.25 32.3372 166.928 31.9997 165.796 31.3241C164.664 30.6287 163.78 29.695 163.144 28.523C162.528 27.3509 162.22 26.0594 162.22 24.6486C162.22 23.2184 162.518 21.9269 163.114 20.7746C163.71 19.6026 164.564 18.6786 165.677 18.0034C166.789 17.308 168.1 16.9604 169.61 16.9604C171.14 16.9604 172.451 17.308 173.544 18.0034C174.657 18.6786 175.511 19.6026 176.107 20.7746C176.703 21.9269 177.001 23.2184 177.001 24.6486V25.5428H164.514C164.634 26.4168 164.912 27.2214 165.349 27.9567C165.806 28.6718 166.412 29.2482 167.167 29.685C167.922 30.1024 168.796 30.3109 169.789 30.3109C170.842 30.3109 171.726 30.0826 172.441 29.6257C173.157 29.1485 173.713 28.5427 174.11 27.8079H176.554C176.037 29.1388 175.213 30.2315 174.08 31.0857C172.968 31.9202 171.527 32.3372 169.759 32.3372ZM164.544 23.4568H174.677C174.478 22.1855 173.941 21.1223 173.067 20.2681C172.193 19.4138 171.041 18.9867 169.61 18.9867C168.18 18.9867 167.028 19.4138 166.154 20.2681C165.299 21.1223 164.763 22.1855 164.544 23.4568Z"
        fill="#222A30"
      />
      <path
        d="M32.2332 46.4992C37.2708 44.661 41.5469 41.1905 44.3793 36.6407C47.212 32.0912 48.4374 26.7251 47.8608 21.3982C47.2838 16.0716 44.938 11.0914 41.1971 7.25213C37.4562 3.41287 32.5361 0.936246 27.2216 0.216932C21.9071 -0.502032 16.5046 0.57764 11.8761 3.28435C7.24801 5.9907 3.66027 10.1679 1.6857 15.1495C-0.288525 20.1311 -0.536356 25.6297 0.982869 30.7682C2.50174 35.9067 6.14732 40.5139 10.514 43.6244L17.0322 33.7396C14.8483 32.184 13.2492 29.9427 12.4896 27.373C11.7303 24.8035 11.8541 22.0538 12.8412 19.5628C13.8286 17.0718 15.6227 14.9828 17.9373 13.6293C20.2519 12.2758 22.9534 11.7358 25.6109 12.0954C28.2686 12.4551 30.729 13.6937 32.5995 15.6135C34.4703 17.5333 35.6436 20.0236 35.9321 22.6876C36.2206 25.3512 35.6075 28.0348 34.1909 30.3097C32.7748 32.585 30.6362 34.3206 28.1172 35.2395L32.2332 46.4992Z"
        fill="#D5EE44"
      />
      <path
        d="M12.5469 45.0602C15.7084 46.827 19.3484 47.8815 23.32 47.9907C27.3676 48.1017 31.023 47.2094 34.2374 45.6563L23.946 26.627L12.5469 45.0602Z"
        fill="#D5EE44"
      />
    </g>
    <defs>
      <clipPath id="clip0_332_3266">
        <rect width="177" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CompanyLogoIcon = (
  <svg width="4.8em" height="4.8em" viewBox="0 0 48 48" fill="none">
    <path
      d="M32.2332 46.4992C37.2708 44.661 41.5469 41.1905 44.3793 36.6407C47.212 32.0912 48.4374 26.7251 47.8608 21.3982C47.2838 16.0716 44.938 11.0914 41.1971 7.25213C37.4562 3.41287 32.5361 0.936246 27.2216 0.216932C21.9071 -0.502032 16.5046 0.57764 11.8761 3.28435C7.24801 5.9907 3.66027 10.1679 1.6857 15.1495C-0.288525 20.1311 -0.536356 25.6297 0.982869 30.7682C2.50174 35.9067 6.14732 40.5139 10.514 43.6244L17.0322 33.7396C14.8483 32.184 13.2492 29.9427 12.4896 27.373C11.7303 24.8035 11.8541 22.0538 12.8412 19.5628C13.8286 17.0718 15.6227 14.9828 17.9373 13.6293C20.2519 12.2758 22.9534 11.7358 25.6109 12.0954C28.2686 12.4551 30.729 13.6937 32.5995 15.6135C34.4703 17.5333 35.6436 20.0236 35.9321 22.6876C36.2206 25.3512 35.6075 28.0348 34.1909 30.3097C32.7748 32.585 30.6362 34.3206 28.1172 35.2395L32.2332 46.4992Z"
      fill="#222A30"
    />
    <path
      d="M12.5459 45.0602C15.7074 46.827 19.3474 47.8815 23.319 47.9907C27.3666 48.1017 31.022 47.2094 34.2364 45.6563L23.945 26.627L12.5459 45.0602Z"
      fill="#222A30"
    />
  </svg>
);

export const Grow = (
  <svg width="44.9em" height="12.9em" viewBox="0 0 449 129" fill="none">
    <title>Grow</title>
    <g clipPath="url(#clip0_272_1976)">
      <path
        d="M280.749 44.6098C271.829 35.0398 260.219 30.2798 246.019 30.2798C231.819 30.2798 220.219 35.0398 211.289 44.6098C202.869 53.7898 198.689 65.4398 198.689 79.4798C198.689 93.5198 202.869 105.16 211.289 114.34C220.223 123.913 231.799 128.69 246.019 128.67C260.219 128.67 271.829 123.91 280.749 114.34C289.159 105.16 293.339 93.5098 293.339 79.4798C293.339 65.4498 289.159 53.7898 280.749 44.6098ZM269.189 103.89C263.539 110.62 255.849 114.01 246.009 114.01C236.169 114.01 228.479 110.62 222.839 103.89C217.409 97.4898 214.659 89.3798 214.659 79.4798C214.659 69.5798 217.409 61.4798 222.839 55.0598C228.479 48.3298 236.169 44.9398 246.009 44.9398C255.849 44.9398 263.539 48.3298 269.189 55.0598C274.619 61.4598 277.369 69.5698 277.369 79.4798C277.369 89.3898 274.619 97.4898 269.189 103.89Z"
        fill="#B5CDC5"
      />
      <path
        d="M192.471 30.2799V46.2799H189.811C180.021 46.2799 172.081 50.1799 165.911 58.0499C160.071 65.5899 157.141 74.5899 157.141 85.1099V127.2H141.141V31.7699H157.141V50.5299C160.714 42.9899 166.714 37.2699 175.141 33.3699C179.962 31.1959 185.214 30.1428 190.501 30.2899L192.471 30.2799Z"
        fill="#B5CDC5"
      />
      <path
        d="M60.19 59.4301V74.1001H105.4C105.17 85.8301 100.81 95.2601 92.3 102.47C84.0067 109.543 74.1367 113.08 62.69 113.08C48.35 113.08 36.91 108.37 28.27 99.0001C20.11 90.1101 16 78.5901 16 64.3401C16 50.0901 20.17 38.6901 28.45 29.6701C32.564 25.2212 37.6066 21.732 43.22 19.4501C49.04 16.8968 56.1367 15.6168 64.51 15.6101C72.278 15.4551 79.9429 17.4076 86.69 21.2601C93.1465 24.808 98.4548 30.1267 101.99 36.5901L102.27 37.0901H119.69L119.09 35.7201C114.512 25.079 106.826 16.0689 97.04 9.87013C92.0198 6.73773 86.5922 4.3116 80.91 2.66013C74.0737 0.771691 67.0013 -0.124138 59.91 0.000129389C51.7599 -0.00397279 43.7042 1.74562 36.29 5.13013C28.9191 8.25431 22.3038 12.9226 16.89 18.8201C5.61669 30.9868 -0.0133097 46.1601 2.36267e-05 64.3401C2.36267e-05 82.7268 5.44336 97.8968 16.33 109.85C21.7598 115.759 28.3506 120.484 35.69 123.73C42.8706 126.934 50.6371 128.616 58.5 128.67C66.15 128.67 72.35 128.05 77.08 126.77C88.4201 123.696 98.387 116.877 105.36 107.42V127.2H120.21V59.4301H60.19Z"
        fill="#B5CDC5"
      />
      <path d="M324.52 63.5898H308.09L321.72 107L329.48 80.3998L324.52 63.5898Z" fill="#B5CDC5" />
      <path
        d="M431.689 2.12012L421.479 33.6801L412.509 64.1001H412.519L393.779 128.38H408.739L408.959 127.7L438.299 34.2501L448.689 2.12012H431.689Z"
        fill="#B5CDC5"
      />
      <path
        d="M391.861 80.7L377.191 33.67L376.971 32.98H360.211L360.001 33.67L343.601 86.21L331.311 128.38H345.951L346.161 127.7L368.591 56.09L384.361 106.43L391.861 80.7Z"
        fill="#B5CDC5"
      />
    </g>
    <defs>
      <clipPath id="clip0_272_1976">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
