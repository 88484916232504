import { css } from 'styled-components';
import { Width } from '../index';

export const Fonts = {
  QUESTRIAL: 'font-family: Questrial, sans-serif',
  WORK_SANS: 'font-family: Work Sans, sans-serif',
};

export const typographyByBreakpoint = (mobile: any, tablet: any, desktop?: any) => css`
  ${mobile};

  ${tablet &&
  css`
    @media print, screen and (min-width: ${Width.BREAK_MD}px) {
      ${tablet};
    }
  `}

  ${desktop &&
  css`
    @media print, screen and (min-width: ${Width.BREAK_XL}px) {
      ${desktop}
    }
  `}
`;

export const typography = (fontSize: string, lineHeight: string | number, letterSpacing = '0.035rem', fontWeight = 400) => css`
  font-size: ${fontSize};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
  font-weight: ${fontWeight};
`;

export const xxlDesktop = css`
  ${Fonts.QUESTRIAL};
  font-size: 16rem;
  font-weight: 400;
  line-height: calc(176 / 160);
  letter-spacing: 0.0078em;
`;

export const xxlMobile = css`
  ${Fonts.QUESTRIAL};
  font-size: 8rem;
  font-weight: 400;
  line-height: calc(66 / 80);
  letter-spacing: 0.0125em;
`;

export const h1Desktop = css`
  ${Fonts.QUESTRIAL};
  font-size: 3.5rem;
  font-weight: 400;
  line-height: calc(70 / 64);
  letter-spacing: 0.015em;
`;

export const h1Mobile = css`
  ${Fonts.QUESTRIAL};
  font-weight: 400;
  font-size: 2.5rem;
  line-height: calc(46 / 42);
  letter-spacing: 0.02em;
`;

export const h2Desktop = css`
  ${Fonts.QUESTRIAL};
  font-size: 3.2rem;
  font-weight: 400;
  line-height: calc(53 / 48);
  letter-spacing: 0.015em;
`;

export const h2Mobile = css`
  ${Fonts.QUESTRIAL};
  font-size: 2.5rem;
  font-weight: 400;
  line-height: calc(33 / 30);
  letter-spacing: 0.025em;
`;

export const h3DesktopBlog = css`
  ${Fonts.QUESTRIAL};
  font-size: 3rem;
  font-weight: 400;
  line-height: calc(33 / 30);
  letter-spacing: 0.025em;
`;

export const h3TabletBlog = css`
  ${Fonts.QUESTRIAL};
  font-size: 2.5rem;
  font-weight: 400;
  line-height: calc(33 / 30);
  letter-spacing: 0.025em;
`;

export const h3MobileBlog = css`
  ${Fonts.QUESTRIAL};
  font-size: 2.0rem;
  font-weight: 400;
  line-height: calc(33 / 30);
  letter-spacing: 0.025em;
`;

export const h3Desktop = css`
  ${Fonts.WORK_SANS};
  font-size: 2.2rem;
  line-height: calc(33 / 22);
  letter-spacing: -0.013em;
  font-weight: 500;
`;

export const bodyBold = css`
  ${Fonts.WORK_SANS};
  font-weight: 600;
  font-size: 1.6rem;
  line-height: calc(26 / 16);
  letter-spacing: -0.0125em;
`;

export const bodyDefault = css`
  ${Fonts.WORK_SANS};
  font-weight: 400;
  font-size: 1.6rem;
  line-height: calc(26 / 16);
  letter-spacing: -0.0125em;
`;

export const buttonDefault = css`
  ${Fonts.WORK_SANS};
  font-weight: 600;
  font-size: 1.6rem;
  line-height: calc(19 / 16);
`;

export const linkDefault = css`
  ${Fonts.WORK_SANS};
  font-weight: 600;
  font-size: 1.6rem;
  line-height: calc(26 / 16);
  letter-spacing: -0.0125em;
  text-decoration: underline;
`;
