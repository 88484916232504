import { Detection } from '../constants';

export function paramsToObj(paramsString: any) {
  return paramsString
    .replace(/(^\?)/, '')
    .split('&')
    .reduce((acc: any, param: any) => {
      const params = param.split('=');
      if (params[0] && params[1]) {
        // if query string already exists, makes the query string an array, or appends to existing array.
        if (acc[params[0]]) {
          if (Array.isArray(acc[params[0]])) {
            acc[params[0]].push(params[1]);
          } else {
            acc[params[0]] = [acc[params[0]], params[1]];
          }
        } else {
          acc[params[0]] = params[1];
        }
      }
      return acc;
    }, {});
}

const queryParams = !Detection.IS_BROWSER ? {} : paramsToObj(window.document.location.search);

export default queryParams;
