import { useEffect, useState } from 'react';

import { Detection } from '../constants';

const useWindowData = (key: number, interval: number = 500, ref) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Detection.IS_BROWSER) {
        if (window[key]) {
          if (ref) {
            ref.current = window[key];
            setData(true);
          } else setData(window[key]);
          clearInterval(interval);
        }
      }
    }, 500);
    return () => clearInterval(interval);
  }, [setData, key, ref]);
  return data;
};

export default useWindowData;
