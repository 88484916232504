import { css } from 'styled-components';
import { BREAK_MD, BREAK_LG, BREAK_XL } from './Width';

export const height = (
  mobile: number | string,
  tablet?: number | string,
  desktop?: number | string,
  largeTablet?: number | string
) => css`
  height: ${typeof mobile === 'string' ? mobile : `${mobile}px`};

  ${tablet &&
  `@media print, screen and (min-width: ${BREAK_MD}px) {
      height: ${typeof tablet === 'string' ? tablet : `${tablet}px`};
    }`};

  ${largeTablet &&
  `@media print, screen and (min-width: ${BREAK_LG}px) {
      height: ${typeof largeTablet === 'string' ? largeTablet : `${largeTablet}px`};
    }`};

  ${desktop &&
  `@media print, screen and (min-width: ${BREAK_XL}px) {
      height: ${typeof desktop === 'string' ? desktop : `${desktop}px`};
    }`};
`;

export const minHeight = (
  mobile: number | string,
  tablet?: number | string,
  desktop?: number | string,
  largeTablet?: number | string
) => css`
  min-height: ${typeof mobile === 'string' ? mobile : `${mobile}px`};

  ${tablet &&
  `@media print, screen and (min-width: ${BREAK_MD}px) {
      min-height: ${typeof tablet === 'string' ? tablet : `${tablet}px`};
    }`};

  ${largeTablet &&
  `@media print, screen and (min-width: ${BREAK_LG}px) {
      min-height: ${typeof largeTablet === 'string' ? largeTablet : `${largeTablet}px`};
    }`};

  ${desktop &&
  `@media print, screen and (min-width: ${BREAK_XL}px) {
      min-height: ${typeof desktop === 'string' ? desktop : `${desktop}px`};
    }`};
`;

export const maxHeight = (
  mobile: number | string,
  tablet?: number | string,
  desktop?: number | string,
  largeTablet?: number | string
) => css`
  max-height: ${typeof mobile === 'string' ? mobile : `${mobile}px`};

  ${tablet &&
  `@media print, screen and (min-width: ${BREAK_MD}px) {
      max-height: ${typeof tablet === 'string' ? tablet : `${tablet}px`};
    }`};

  ${largeTablet &&
  `@media print, screen and (min-width: ${BREAK_LG}px) {
        max-height: ${typeof largeTablet === 'string' ? largeTablet : `${largeTablet}px`};
      }`};

  ${desktop &&
  `@media print, screen and (min-width: ${BREAK_XL}px) {
      max-height: ${typeof desktop === 'string' ? desktop : `${desktop}px`};
    }`};
`;
