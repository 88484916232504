import { css } from 'styled-components';
import { BREAK_MD, BREAK_LG, BREAK_XL } from './Width';

export const padding = (mobile: string | number, tablet?: string | number, desktop?: string | number, largeTablet?: string | number) => css`
  padding: ${mobile};

  ${typeof tablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_MD}px) {
      padding: ${tablet};
    }`}

  ${typeof largeTablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_LG}px) {
      padding: ${largeTablet};
    }`}

  ${typeof desktop !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      padding: ${desktop};
    }`}
`;

export const paddingTop = (mobile: number, tablet?: number, desktop?: number, largeTablet?: number) => css`
  padding-top: ${mobile}px;

  ${typeof tablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_MD}px) {
      padding-top: ${tablet}px;
    }`}

  ${typeof largeTablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_LG}px) {
      padding-top: ${largeTablet}px;
    }`}

  ${typeof desktop !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      padding-top: ${desktop}px;
    }`}
`;

export const paddingBottom = (mobile: number, tablet?: number, desktop?: number, largeTablet?: number) => css`
  padding-bottom: ${mobile}px;

  ${typeof tablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_MD}px) {
      padding-bottom: ${tablet}px;
    }`}

  ${typeof largeTablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_LG}px) {
      padding-bottom: ${largeTablet}px;
    }`}

  ${typeof desktop !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      padding-bottom: ${desktop}px;
    }`}
`;

export const paddingTopBottom = (
  [mobileTop, mobileBottom]: number[],
  [tabletTop, tabletBottom]: number[],
  [desktopTop, desktopBottom]: number[],
  [largeTabletTop, largeTabletBottom]: number[] = [tabletTop, tabletBottom]
) => css`
  padding-top: ${mobileTop}px;
  padding-bottom: ${mobileBottom}px;

  @media print, screen and (min-width: ${BREAK_MD}px) {
    padding-top: ${tabletTop}px;
    padding-bottom: ${tabletBottom}px;
  }

  ${typeof largeTabletTop !== 'undefined' &&
  typeof largeTabletBottom !== 'undefined' &&
  `@media print, screen and (min-width: ${BREAK_LG}px) {
      padding-top: ${largeTabletTop}px;
      padding-bottom: ${largeTabletBottom}px;
    }`};

  @media print, screen and (min-width: ${BREAK_XL}px) {
    padding-top: ${desktopTop}px;
    padding-bottom: ${desktopBottom}px;
  }
`;

export const paddingLeftRight = (
  [mobileLeft, mobileRight]: number[],
  [tabletLeft, tabletRight]: number[],
  [desktopLeft, desktopRight]: number[],
  [largeTabletLeft, largeTabletRight]: number[] = [tabletLeft, tabletRight]
) => css`
  padding-left: ${mobileLeft}px;
  padding-right: ${mobileRight}px;

  @media print, screen and (min-width: ${BREAK_MD}px) {
    padding-left: ${tabletLeft}px;
    padding-right: ${tabletRight}px;
  }

  ${typeof largeTabletLeft !== 'undefined' &&
  typeof largeTabletRight !== 'undefined' &&
  `@media print, screen and (min-width: ${BREAK_LG}px) {
      padding-left: ${largeTabletLeft}px;
      padding-right: ${largeTabletRight}px;
    }`};

  @media print, screen and (min-width: ${BREAK_XL}px) {
    padding-left: ${desktopLeft}px;
    padding-right: ${desktopRight}px;
  }
`;
