export const ENTER = [13, 'Enter'];
export const SPACE = [32, ' ', 'Spacebar'];
export const TAB = [9, 'Tab'];
export const UP = [38, 'ArrowUp'];
export const DOWN = [40, 'ArrowDown'];
export const ESC = [27, 'Escape'];
export const HOME = [36, 'Home'];
export const END = [35, 'End'];
export const LEFT = [37, 'ArrowLeft'];
export const RIGHT = [39, 'ArrowRight'];
