export const HOLIDAY_DATA = [
    {
        holiday: `Juneteenth`,
        startDate: new Date('2023-06-14').toLocaleDateString('en-US'),
        endDate: new Date('2023-06-20').toLocaleDateString('en-US'),
    },
    {
        holiday: `Fourth of July`,
        startDate: new Date('2023-06-29').toLocaleDateString('en-US'),
        endDate: new Date('2023-07-05').toLocaleDateString('en-US'),
    },
    {
        holiday: `Labor Day`,
        startDate: new Date('2023-08-31').toLocaleDateString('en-US'),
        endDate: new Date('2023-09-05').toLocaleDateString('en-US'),
    },
    {
        holiday: `Thanksgiving`,
        startDate: new Date('2023-11-18').toLocaleDateString('en-US'),
        endDate: new Date('2023-11-25').toLocaleDateString('en-US'),
    },
    {
        holiday: `Christmas`,
        startDate: new Date('2023-12-20').toLocaleDateString('en-US'),
        endDate: new Date('2023-12-26').toLocaleDateString('en-US'),
    },
    {
        holiday: `New Years`,
        startDate: new Date('2023-12-26').toLocaleDateString('en-US'),
        endDate: new Date('2024-01-02').toLocaleDateString('en-US'),
    },

];
  