export const NAV_DATA = [
  {
    title: `About us`,
    menuItems: [
      {
        title: 'Our Mission',
        url: '/mission',
      },
      {
        title: 'Team',
        url: '/leadership#team',
      },
      {
        title: 'Advisors',
        url: '/leadership#advisors',
      },
      { title: 'Contact', url: '/contact' },
      { title: 'Careers', url: '/careers' },
    ],
  },
  {
    title: `Products`,
    url: `/products`,
  },
  {
    title: `Community`,
    menuItems: [
      {
        title: `Ready Neighborhood`,
        url: `/community`,
      },
      {
        title: `Blogs`,
        url: `/community/blogs`
      },
      {
        title: `Events`,
        url: `/community/events`,
      },
      {
        title: `News & Media`,
        url: `/community/news`,
      },
    ],
  },
];
