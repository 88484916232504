import { css } from 'styled-components';
import { BREAK_MD, BREAK_LG, BREAK_XL } from './Width';

export const absolute = (
  top: string | number = 0,
  right: string | number = 0,
  bottom: string | number = 0,
  left: string | number = 0
) => css`
  position: absolute;
  top: ${top};
  right: ${right};
  bottom: ${bottom};
  left: ${left};
`;

export const absoluteBottom = (mobile: number, tablet?: number, desktop?: number, largeTablet?: number) => css`
  bottom: ${mobile}px;

  ${typeof tablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_MD}px) {
      bottom: ${tablet}px;
    }`}

  ${typeof largeTablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_LG}px) {
      bottom: ${largeTablet}px;
    }`}

  ${typeof desktop !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      bottom: ${desktop}px;
    }`}
`;

export const position = (
  prop: string,
  mobile: number | string,
  tablet?: number | string,
  desktop?: number | string,
  largeTablet?: number | string
) => css`
  ${prop}: ${typeof mobile === 'number' ? `${mobile}px` : `${mobile}`};

  ${typeof tablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_MD}px) {
    ${prop}: ${typeof tablet === 'number' ? `${tablet}px` : `${tablet}`};
  }`}

  ${typeof largeTablet !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_LG}px) {
      ${prop}: ${typeof largeTablet === 'number' ? `${largeTablet}px` : `${largeTablet}`};
    }`}

  ${typeof desktop !== 'undefined' &&
  `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      ${prop}: ${typeof desktop === 'number' ? `${desktop}px` : `${desktop}`};
    }`}
`;
