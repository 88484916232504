import React, { createContext, useContext, useEffect } from 'react';
import type { ReactNode } from 'react';
import { useImmerReducer } from 'use-immer';
import { closeFullscreenOverlay, initFullscreenOverlay, removeTabindex, restoreTabIndex } from '../../utils';
import { BODY_ID, DESKTOP_NAV_ID } from '../../constants';

const ModalContextActions = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  SET_VIDEO_SRC: 'SET_VIDEO_SRC',
  SET_VIDEO_ID_YOUTUBE: 'SET_VIDEO_ID_YOUTUBE',
  SET_ROOT_EL: 'SET_ROOT_EL',
};

type ActionsType =
  | {
      type: ModalContextActions.SET_ROOT_EL,
      payload: HTMLElement,
    }
  | {
      type: ModalContextActions.OPEN,
      payload: string,
    }
  | {
      type: ModalContextActions.CLOSE,
      payload: string,
    }
  | {
      type: ModalContextActions.SET_VIDEO_SRC,
      payload: {
        src: string,
        poster: string,
      },
    }
  | {
      type: ModalContextActions.SET_VIDEO_ID_YOUTUBE,
      payload: string,
    };

type State = {
  isOpen: boolean,
  id: string,
  video: {
    src: string,
    poster: string,
    youtubeId: string,
  },
  rootEl: HTMLElement | Element | null,
};

const initialState: State = {
  isOpen: false,
  id: '',
  video: {
    src: '',
    poster: '',
    youtubeId: '',
  },
  rootEl: null,
};

const reducer = (draft: State, action: ActionsType): void => {
  const { type, payload } = action;
  switch (type) {
    case ModalContextActions.SET_ROOT_EL:
      draft.rootEl = payload;
      break;
    case ModalContextActions.OPEN:
      initFullscreenOverlay(payload);
      removeTabindex(BODY_ID, payload);
      removeTabindex(DESKTOP_NAV_ID, payload);
      draft.isOpen = true;
      draft.id = payload;
      break;
    case ModalContextActions.CLOSE:
      closeFullscreenOverlay(payload);
      restoreTabIndex(payload);
      draft.isOpen = false;
      draft.video.youtubeId = '';
      break;
    case ModalContextActions.SET_VIDEO_SRC:
      draft.video = payload;
      break;
    case ModalContextActions.SET_VIDEO_ID_YOUTUBE:
      draft.video = {
        youtubeId: payload,
      };
  }
};

const MODAL_ROOT_ID = 'gatsby-focus-wrapper';

const DispatchContext: any = createContext(undefined);
const StateContext: any = createContext(undefined);

const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  useEffect(() => {
    dispatch({
      type: ModalContextActions.SET_ROOT_EL,
      payload: document.getElementById(MODAL_ROOT_ID),
    });
  }, [dispatch]);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
};

type Dispatch = (action: ActionsType) => void;

const useModalDispatch = (): Dispatch => useContext(DispatchContext);
const useModalState = (): State => useContext(StateContext);

export { ModalContextProvider, useModalDispatch, useModalState, ModalContextActions };
