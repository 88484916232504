import React from 'react';
import styled from 'styled-components';
import { displayNone } from '../../../styles';

const Br = styled.br`
  ${({ mobile, tablet, desktop, largeTablet }) => displayNone('block', !mobile, !tablet, !desktop, !largeTablet)};
`;

type Props = {
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  largeTablet?: boolean;
};

const LineBreak = ({ mobile, tablet, desktop, largeTablet = tablet }: Props) => (
  <Br mobile={mobile} tablet={tablet} desktop={desktop} largeTablet={largeTablet} />
);

export default LineBreak;
