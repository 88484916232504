import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

const Metadata = props => {
  const {
    seoMetadata,
    location: { pathname },
  } = props;

  const { title, description } = seoMetadata || {};

  const {
    site: {
      siteMetadata: { description: fallbackDescription, image, siteUrl, title: fallbackTitle },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          image
          siteUrl
          title
        }
      }
    }
  `);

  const descriptionWithFallback = description || fallbackDescription;
  const titleWithFallback = title || fallbackTitle;

  const tags = [
    {
      name: 'description',
      content: descriptionWithFallback,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:url',
      content: `${siteUrl}${pathname}`,
    },
    {
      property: 'og:title',
      content: titleWithFallback,
    },
    {
      property: 'og:description',
      content: descriptionWithFallback,
    },
    {
      property: 'og:image',
      content: image,
    },
  ];

  return (
    <Helmet title={titleWithFallback} meta={tags}>
      <link rel="canonical" href={`${siteUrl}${pathname}`} />
    </Helmet>
  );
};

export default Metadata;
