import { useEffect, useRef } from 'react';

import { Detection } from '../constants';

const Hammer = Detection.IS_BROWSER ? require('hammerjs') : null;

const useHammerSwipe = (
  onRightOrDown,
  onLeftOrUp,
  vertical = false,
  { threshold = 1, velocity = 0.1 }: { threshold?: number; velocity?: number } = {}
) => {
  const elRef = useRef(null);
  const hammerRef = useRef(null);
  useEffect(() => {
    if (Detection.IS_BROWSER) {
      if (elRef.current) {
        hammerRef.current = new Hammer(elRef.current, {
          threshold,
          velocity,
        });

        if (vertical) {
          hammerRef.current.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });
          hammerRef.current.on('swipe', ({ deltaY }) => {
            deltaY > 0 ? onRightOrDown() : onLeftOrUp();
          });
        } else {
          hammerRef.current.on('swipe', ({ deltaX }) => {
            deltaX > 0 ? onRightOrDown() : onLeftOrUp();
          });
        }
      }
    }
    return () => {
      if (hammerRef.current) hammerRef.current.destroy();
    };
  }, [elRef, onLeftOrUp, onRightOrDown, vertical, threshold, velocity]);
  return elRef;
};

export default useHammerSwipe;
