import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { CompanyLogo } from '../../../Svg';
import { Detection } from '../../../../constants';
import { Color, Layout, maxWidthContainer, Width, Transition, maxWidth } from '../../../../styles';
import { NavButtons } from './index';
import { usePageState } from '../../SiteContainer';

const Wrapper = styled.header<{ $atTop?: boolean; $theme?: 'LIGHT' | 'DARK' }>`
  --primary: ${({ $theme }) => ($theme === 'DARK' ? Color.GREEN : Color.BLACK)};
  --secondary: ${({ $theme }) => ($theme === 'DARK' ? Color.BLACK : Color.BLACK)};
  width: 100%;
  position: fixed;
  will-change: height;
  height: ${({ $atTop }) => ($atTop ? `${Layout.NAV_HEIGHT_DESKTOP / 10}rem` : `7.3rem`)};
  z-index: ${Layout.Z_INDEX_DESKTOP_NAV};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background ${Transition.DEFAULT}, height ${Transition.DEFAULT};
  background: ${({ $atTop }) => ($atTop ? 'transparent' : 'rgba(255, 255, 255, 0.94)')};

  > div:first-of-type {
    ${maxWidthContainer()};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .logo-link {
    svg {
      will-change: width;
      transition: width ${Transition.DEFAULT};
    }

    svg path {
      fill: ${({ $atTop }) => ($atTop ? 'var(--primary)' : 'var(--secondary)')};
    }
  }

  @media print, screen and (max-width: ${Width.BREAK_MOBILE_NAV - 1}px) {
    display: none;
  }
`;

const DesktopNav = ({ enabled }: { enabled: boolean }) => {
  const [atTop, setAtTop] = useState(true);
  const pageTheme = usePageState(state => state.pageTheme);

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (window.scrollY <= 0) {
            setAtTop(true);
          } else {
            setAtTop(false);
          }
          ticking = false;
        });
      }
      ticking = true;
    };

    if (Detection.IS_BROWSER) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return useMemo(
    () => (
      <Wrapper id={'nav'} $atTop={atTop} $theme={pageTheme}>
        <div>
          <Link className={'logo-link'} to={'/'}>
            <CompanyLogo />
          </Link>
          <NavButtons atTop={atTop} visible={enabled}/>
        </div>
      </Wrapper>
    ),
    [pageTheme, atTop]
  );
};

export default DesktopNav;
