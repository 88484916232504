import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { Color, buttonDefault, Transition } from '../../../styles';
import { addClassName } from '../../../utils';

const THEMES = {
  BORDER_BLACK: 'BORDER_BLACK',
  SOLID_GREEN: 'SOLID_GREEN',
};

export type ButtonType = {
  children: any;
  theme?: string;
  url?: string;
  className?: string;
  colorTheme?: string;
  onClick?: () => void;
};

const Wrapper = styled.div<{ $theme: string; $colorTheme: string }>`
  display: inline-block;

  a {
    text-decoration: none;
  }

  border-radius: 100px;
  ${buttonDefault};
  border: none;
  will-change: background-color, border, color;
  transition: background-color ${Transition.DEFAULT}, border ${Transition.DEFAULT}, color ${Transition.DEFAULT};
  padding: 12px 24px;

  &:hover {
    cursor: pointer;
  }

  ${({ $theme, $colorTheme }) => {
    switch ($theme) {
      case THEMES.SOLID_GREEN:
        return css`
          background-color: ${Color.GREEN};
          border: 1px solid ${Color.GREEN};
          color: ${Color.BLACK};

          &:hover {
            background-color: transparent;
            border: 1px solid ${Color.GREEN};
            color: ${Color.GREEN};
          }
        `;
      case THEMES.BORDER_BLACK:
        return css`
          background-color: transparent;
          border: 1px solid ${Color.BLACK};
          color: ${Color.BLACK};

          &:hover {
            background-color: ${Color.BLACK};
            border: 1px solid ${Color.BLACK};
            color: ${Color.WHITE};
          }
        `;
      default:
        if ($colorTheme === 'DARK') {
          return css`
            background-color: ${Color.GREEN};
            border: 1px solid ${Color.GREEN};
            color: ${Color.BLACK};

            &:hover {
              background-color: transparent;
              border: 1px solid ${Color.GREEN};
              color: ${Color.GREEN};
            }
          `;
        } else {
          return css`
            background-color: ${Color.BLACK};
            border: 1px solid ${Color.BLACK};
            color: ${Color.WHITE};

            &:hover {
              background-color: transparent;
              border: 1px solid ${Color.BLACK};
              color: ${Color.BLACK};
            }
          `;
        }
    }
  }};
`;

const Button = ({ children, theme, url, className, colorTheme, onClick }: ButtonType) => {
  const Component = url ? Link : 'button';
  return (
    <Wrapper
      as={Component}
      to={url ? url : null}
      className={addClassName('button-wrapper', className)}
      $theme={theme}
      $colorTheme={colorTheme}
      onClick={onClick}>
      {children}
    </Wrapper>
  );
};

export default Button;
