import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { absolute, Color, flex, hoverChild, Layout, Width } from '../../../styles';
import { useModalDispatch, useModalState, ModalContextActions } from '../../../contexts/modal-context';
import { Close } from '../../Svg';
import { ESC, VIDEO_PLAYER } from '../../../constants';
import { addFocusClassName } from '../../../utils';
import { useYoutubeApi } from '../../../hooks';

const DesktopWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: ${Layout.Z_INDEX_MODAL};
  transform: ${({ visible }) => (visible ? `scale(1)` : `scale(0)`)};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: ${({ visible }) => (visible ? `opacity 300ms linear` : `opacity 200ms linear, transform 0ms linear 200ms`)};
  ${flex()};
`;

const CloseButton = styled.button`
  cursor: pointer;
  ${absolute('10px', '30px', 'unset', 'unset')};
  ${flex()};
  width: 32px;
  height: 32px;
  ${hoverChild('line', 'stroke', Color.WHITE, Color.YELLOW_MELLOW)};
  display: none;

  @media print, screen and (min-width: ${Width.BREAK_MOBILE_NAV}px) {
    display: flex;
  }

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    position: absolute;
    top: -32px;
    right: -32px;
  }

  svg {
    width: 16px;
  }
`;

const Player = styled.div`
  width: 920px;
  max-width: 100vw;

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    max-width: calc(100vw - 200px);
  }

  > div {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;

    > div {
      ${absolute()};

      video {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }
  }
`;

const IFrame = styled.div`
  background: black;
  ${absolute()};
  z-index: 1;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};

  iframe {
    display: ${({ visible }) => (visible ? 'unset' : 'none')};
  }
`;

const YOUTUBE_PLAYER_EL = 'figure-yt-player';

const VideoPlayerModal = () => {
  const {
    isOpen,
    id,
    video: { src, poster, youtubeId },
  } = useModalState();
  const dispatch = useModalDispatch();
  const [ytPlayerTimeCache, setYtPlayerTimeCache] = useState({});
  const ytPlayerRef = useRef(null);
  const youtubeIdRef = useRef('');
  const [ytPlayerReady, setYtPlayerReady] = useState(false);
  const [ytPlayerState, setYtPlayerState] = useState(-1);
  const closeOnClick = useCallback(() => {
    dispatch({
      type: ModalContextActions.CLOSE,
      payload: VIDEO_PLAYER,
    });
  }, [dispatch]);
  const { YT } = useYoutubeApi();
  useEffect(() => {
    if (YT && YT.Player) {
      ytPlayerRef.current = new YT.Player(YOUTUBE_PLAYER_EL, {
        height: '100%',
        width: '100%',
        playerVars: {
          playsinline: 1,
          modestbranding: 1,
          rel: 0,
        },
        events: {
          onReady: () => setYtPlayerReady(true),
          onStateChange: ({ data }) => setYtPlayerState(data),
        },
      });
    }
  }, [YT]);
  useEffect(() => {
    if (ytPlayerRef.current?.loadVideoById && ytPlayerReady && youtubeId) {
      ytPlayerRef.current.loadVideoById({
        videoId: youtubeId,
        startSeconds: ytPlayerTimeCache[youtubeId] || 0,
      });
      youtubeIdRef.current = youtubeId;
    }
  }, [ytPlayerReady, youtubeId, ytPlayerTimeCache]);
  useEffect(() => {
    if (ytPlayerState === 0) closeOnClick();
  }, [ytPlayerState, closeOnClick]);
  const playerRef = useRef(null);
  const closeOnEsc = useCallback(
    ({ key, keyCode }) => {
      if (ESC.includes(key || keyCode)) closeOnClick();
    },
    [closeOnClick]
  );
  const showing = isOpen && VIDEO_PLAYER === id;
  useEffect(() => {
    if (playerRef.current) playerRef.current.addEventListener('webkitendfullscreen', closeOnClick);
    //eslint-disable-next-line
  }, [playerRef.current, closeOnClick]);
  useEffect(() => {
    if (showing && playerRef.current && src) playerRef.current.play();
    if (!showing && playerRef.current && src) playerRef.current.pause();
    if (!showing && ytPlayerRef.current) {
      if (youtubeIdRef.current) {
        if (ytPlayerRef.current.getCurrentTime) {
          setYtPlayerTimeCache(ytPlayerTimeCache => ({
            ...ytPlayerTimeCache,
            [youtubeIdRef.current]: ytPlayerRef.current.getCurrentTime(),
          }));
        }
        setTimeout(() => {
          youtubeIdRef.current = '';
        }, 0);
      }
      if (ytPlayerRef.current?.stopVideo) ytPlayerRef.current.stopVideo();
    }
  }, [showing, src, youtubeId]);
  return useMemo(
    () => (
      <DesktopWrapper aria-hidden={!showing} visible={showing} role="dialog" onKeyDown={closeOnEsc} onClick={closeOnClick}>
        {/* eslint-disable-next-line */}
        <div onClick={e => e.stopPropagation()}>
          <Player>
            <div>
              <div>
                <IFrame visible={!!youtubeId} aria-hidden={!!youtubeId}>
                  <div id={YOUTUBE_PLAYER_EL} />
                </IFrame>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                {src && <video preload="none" ref={playerRef} controls playsInline src={src} poster={poster} onEnded={closeOnClick} />}
                <CloseButton
                  tabIndex={showing ? null : -1}
                  className={addFocusClassName()}
                  label="Close the modal."
                  onClick={closeOnClick}>
                  {Close}
                </CloseButton>
              </div>
            </div>
          </Player>
        </div>
      </DesktopWrapper>
    ),
    [showing, closeOnEsc, closeOnClick, youtubeId, src, poster]
  );
};

export default VideoPlayerModal;
