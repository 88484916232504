import React from 'react';
import { UIContainer } from '../UI';
// @ts-ignore

import { LockScreenContextProvider } from '../../../contexts/lock-screen-context';
import { ModalContextProvider } from '../../../contexts/modal-context';
import create from 'zustand';

export const usePageState = create<{ pageTheme?: string; setPageTheme: Function }>(set => ({
  pageTheme: undefined,
  setPageTheme: theme => set({ pageTheme: theme }),
}));

const SiteContainer = ({ children, ...rest }: any) => {
  return (
    <LockScreenContextProvider>
      <ModalContextProvider>
        <UIContainer {...rest}>{children}</UIContainer>
      </ModalContextProvider>
    </LockScreenContextProvider>
  );
};

export default SiteContainer;
