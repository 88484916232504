import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  bodyBold,
  bodyDefault,
  Color,
  displayNone,
  flex,
  flexGap,
  visuallyHiddenCss,
  Width,
  maxWidthContainer,
  paddingBottom,
} from '../../styles';
import { Close } from '../Svg';

const BannerWrapper = styled.div<{ isOpen: boolean }>`
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 10px 0 30px rgba(0, 0, 0, 0.3);
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-top: 32px;
  ${paddingBottom(126, 32)};
  ${flex('center', 'flex-start', 'column', 'nowrap')}
  ${flexGap('0.8rem')}
  color: ${Color.BLACK};
  z-index: 6;

  ${({ isOpen }) =>
    css`
      visibility: ${isOpen ? 'visible' : 'hidden'};
    `}
`;

/* Added this div to keep text aligned with logo on different viewports */
const BannerContent = styled.div`
  ${maxWidthContainer()};

  > * + * {
    margin-top: 8px;
  }

  .bold-text {
    ${bodyBold};
    /* Prevents Zendesk widget from overlapping banner text */
    @media print, screen and (min-width: ${Width.BREAK_MD}px) {
      max-width: 75%;
    }

    @media print, screen and (min-width: ${Width.BREAK_XL}px) {
      max-width: 90ch;
    }
  }

  .sub-text {
    font-size: 1.2rem;
    line-height: 2rem;

    /* Prevents Zendesk widget from overlapping banner text */
    @media print, screen and (min-width: ${Width.BREAK_MD}px) and (max-width: ${Width.BREAK_XL - 1}px) {
      max-width: 75%;
    }
  }

  a {
    color: inherit;
    font-weight: inherit;
    text-decoration-line: underline;
  }
`;

const VisuallyHidden = styled.span`
  ${visuallyHiddenCss};
`;

const BannerButton = styled.button`
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  background-color: ${Color.WHITE};
  box-shadow: 10px 0 30px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: -2.6rem;
  right: 1.9rem;

  ${displayNone('flex', false, false, true)}
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  svg {
    width: 1.6rem;
  }

  .visible {
    ${visuallyHiddenCss};
  }

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    display: inline;
    position: static;
    width: auto;
    height: auto;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    ${bodyDefault};
    text-decoration-line: underline;

    svg {
      display: none;
    }

    .visible {
      position: static;
      margin: 0;
    }
  }
`;

const Banner = ({ boldText, bodyText }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <BannerWrapper role="dialog" isOpen={isOpen}>
      <BannerContent>
        <p className="bold-text">{boldText}</p>
        <p className="sub-text">{bodyText}</p>
        <BannerButton
          onClick={() => {
            setIsOpen(false);
          }}>
          {Close}
          <span className="visible">Dismiss</span>
          <VisuallyHidden>banner</VisuallyHidden>
        </BannerButton>
      </BannerContent>
    </BannerWrapper>
  );
};

export default Banner;
