import { css } from 'styled-components';
import { BREAK_LG, BREAK_MD, BREAK_XL } from './Width';

type AlignOptions = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch' | 'baseline';

type FlexDirections = 'row' | 'column' | 'row-reverse' | 'column-reverse';

type FlexWrap = 'wrap' | 'nowrap';

type AlignContentOptions = 'left' | 'center' | 'right';

export type FlexOptions = [AlignOptions, AlignOptions, FlexDirections, FlexWrap];

export const flex = (
  justify: AlignOptions = 'center',
  align: AlignOptions = 'center',
  flexDirecton: FlexDirections = 'row',
  flexWrap: FlexWrap = 'nowrap'
) => css`
  display: flex;
  justify-content: ${justify};
  align-items: ${align};
  flex-direction: ${flexDirecton};
  flex-wrap: ${flexWrap};
`;

export const inlineFlex = (
  justify: AlignOptions = 'center',
  align: AlignOptions = 'center',
  flexDirecton: FlexDirections = 'row'
) => css`
  display: inline-flex;
  justify-content: ${justify};
  align-items: ${align};
  flex-direction: ${flexDirecton};
`;

export const flexInlineFlex = (mobileIsFlex: boolean, tabletIsFlex: boolean = true, desktopIsFlex: boolean = true) => css`
  display: ${mobileIsFlex ? 'flex' : 'inline-flex'};
  @media print, screen and (min-width: ${BREAK_MD}px) {
    display: ${tabletIsFlex ? 'flex' : 'inline-flex'};
  }
  @media print, screen and (min-width: ${BREAK_XL}px) {
    display: ${desktopIsFlex ? 'flex' : 'inline-flex'};
  }
`;

export const alignSelf = (mobile: AlignOptions, tablet?: AlignOptions, desktop?: AlignOptions) => css`
  align-self: ${mobile};

  ${tablet &&
  `@media print, screen and (min-width: ${BREAK_MD}px) {
       align-self: ${tablet};
    }`};

  ${desktop &&
  `@media print, screen and (min-width: ${BREAK_XL}px) {
      align-self: ${desktop};
    }`};
`;

export const justifySelf = (mobile: AlignOptions, tablet?: AlignOptions, desktop?: AlignOptions) => css`
  justify-self: ${mobile};

  ${tablet &&
  `@media print, screen and (min-width: ${BREAK_MD}px) {
       justify-self: ${tablet};
    }`};

  ${desktop &&
  `@media print, screen and (min-width: ${BREAK_XL}px) {
      justify-self: ${desktop};
    }`};
`;

const ALIGN: any = {
  left: {
    flex: 'flex-start',
    text: 'left',
  },
  center: {
    flex: 'center',
    text: 'center',
  },
  right: {
    flex: 'flex-end',
    text: 'right',
  },
};

export const alignItemsTextAlign = (mobile: AlignContentOptions, tablet?: AlignContentOptions, desktop?: AlignContentOptions) => css`
  align-items: ${ALIGN[mobile].flex};
  * {
    text-align: ${ALIGN[mobile].text};
  }

  ${tablet &&
  `@media print, screen and (min-width: ${BREAK_MD}px) {
       align-items: ${ALIGN[tablet].flex};
        * {
          text-align: ${ALIGN[tablet].text};
        }
    }`};

  ${desktop &&
  `@media print, screen and (min-width: ${BREAK_XL}px) {
      align-items: ${ALIGN[desktop].flex};
        * {
          text-align: ${ALIGN[desktop].text};
        }
    }`};
`;

// Does NOT support Safari browsers on lower version iOS. Use with caution. Current supported version is 14.5 or higher (Released on April 2021)
export const flexGap = (
  mobile: number | string,
  tablet?: number | string,
  desktop?: number | string,
  largeTablet?: number | string
) => css`
  gap: ${typeof mobile === 'string' ? mobile : `${mobile}px`};

  ${tablet &&
  `@media print, screen and (min-width: ${BREAK_MD}px) {
      gap: ${typeof tablet === 'string' ? tablet : `${tablet}px`};
    }`};

  ${largeTablet &&
  `@media print, screen and (min-width: ${BREAK_LG}px) {
      gap: ${typeof largeTablet === 'string' ? largeTablet : `${largeTablet}px`};
    }`};

  ${desktop &&
  `@media print, screen and (min-width: ${BREAK_XL}px) {
      gap: ${typeof desktop === 'string' ? desktop : `${desktop}px`};
    }`};
`;
